import * as React from "react"
import { Button } from "react-bootstrap"
import { decodeAudioData, unlockAudio } from "../../common/UnlockAudio"
import { playAudio } from "../Metronome/Classes"
import Slider from "react-input-slider"
import { message, Popover } from "antd"
import styles from "./TapToBPM.module.css"
import { getMessageConfig } from "../../containers/DarkModeContainers/CustomMessage"
import {
  requestDeviceMotionIfNeeded,
  startShakeDetection,
} from "../../common/DeviceMotion"
import {
  getLocalStorageValue,
  setLocalStorageValue,
} from "../../storage/LocalStorageHandler"
import { getMountNode } from "../../containers/DarkModeContainers/CustomMountNode"
import { mobileDetect } from "../../common/MobileDetect"
const audiofile = require("../../assets/sounds/metronome/woodblock.mp3").default

let audiobuf: AudioBuffer

interface IProps {}

interface IState {
  currSum: number
  currCount: number
  lastMS: number
  start: boolean
  currBpm: number
  mute: boolean
  toggle: boolean
  btnLoading: boolean
  displayPopover: boolean // for sensitivity popover %
  devicemotionEnabled: boolean
  devicemotionSensitivity: number // 0 to 100
  disposeDeviceMotion: undefined | (() => any)
}

export class TapToBPM extends React.Component<IProps, IState> {
  md = mobileDetect

  audio: any

  constructor(props: any) {
    super(props)
    this.state = {
      currSum: 0,
      currCount: 0,
      currBpm: 0,
      lastMS: 0,
      start: false,
      mute: this.md.mobile() === "iPhone", // disable on iPhone
      toggle: false,
      btnLoading: true,
      devicemotionEnabled: false,
      disposeDeviceMotion: undefined,
      devicemotionSensitivity: 75,
      displayPopover: false,
    }
  }

  componentDidMount = () => {
    fetch(audiofile)
      .then((resp) => resp.arrayBuffer())
      .then((buf) => {
        decodeAudioData(buf)
          .then((x) => {
            audiobuf = x
            this.setState({
              ...this.state,
              btnLoading: false,
            })
          })
          .catch((e) => {
            console.error(e)
            message.config(getMessageConfig())
            message.error("Something went wrong.")
          })
      })
    const localSens = parseInt(getLocalStorageValue("devicemotionSensLevel"))
    this.setState({ ...this.state, devicemotionSensitivity: localSens })
  }

  componentWillUnmount = () => {
    const { disposeDeviceMotion } = this.state
    if (disposeDeviceMotion) disposeDeviceMotion()
    document.onkeydown = null
  }

  handleSenStateChange = (newSen: any) => {
    setLocalStorageValue("devicemotionSensLevel", newSen.x.toString())
    this.setState({
      ...this.state,
      devicemotionSensitivity: newSen.x,
      displayPopover: true,
    })
  }

  onSenDragEnd = () => {
    this.setState({
      ...this.state,
      displayPopover: false,
    })
  }

  render() {
    const update = async () => {
      const { toggle, currSum, currCount, lastMS } = this.state
      const nowMS: number = Date.now()
      const gapMS: number = nowMS - lastMS
      const nextCurrBpm: number = 60000.0 / gapMS
      const nextCurrSum: number = currSum * 1 + nextCurrBpm
      const nextCurrCount: number = currCount * 1 + 1
      this.setState({
        ...this.state,
        lastMS: nowMS,
        currSum: nextCurrSum,
        currCount: nextCurrCount,
        currBpm: nextCurrBpm,
        toggle: !toggle,
      })
    }

    const enableDeviceMotion = async () => {
      try {
        await requestDeviceMotionIfNeeded()

        const { devicemotionSensitivity } = this.state

        // 1 to 21
        const requiredSens = 20 - (devicemotionSensitivity / 100) * 20 + 1

        const disposeDeviceMotion = await startShakeDetection(
          handleTap,
          requiredSens
        )

        this.setState({
          ...this.state,
          devicemotionEnabled: true,
          disposeDeviceMotion,
        })
      } catch (err) {
        message.config(getMessageConfig())
        message.error(`Something went wrong.`)
      }
    }

    const disableDeviceMotion = async () => {
      const { disposeDeviceMotion } = this.state
      if (disposeDeviceMotion) disposeDeviceMotion()
      this.setState({
        ...this.state,
        devicemotionEnabled: false,
        disposeDeviceMotion: undefined,
      })
    }

    const handleTap = async () => {
      const { toggle, mute, start } = this.state
      if (!mute) {
        playAudio(audiobuf)
      }

      const nowMS: number = Date.now()
      if (!start) {
        this.setState({
          ...this.state,
          start: true,
          lastMS: nowMS,
          currCount: 1,
          toggle: !toggle,
        })
      } else {
        update()
      }
    }
    const handleReset = () => {
      const { start } = this.state
      if (start) {
        this.setState({
          ...this.state,
          currSum: 0,
          currCount: 0,
          start: false,
        })
      }
    }

    const getInstBPM = (): string => {
      const { start, currCount, currBpm } = this.state
      if (start) {
        if (currCount === 1) return "0"
        return currBpm.toFixed(0).toString()
      }
      return "---"
    }

    const getAvgBPM = (): string => {
      const { start, currCount, currSum } = this.state
      if (start) {
        if (currCount === 1) return "0"
        return ((1.0 * currSum) / (currCount - 1)).toFixed(0).toString()
      }
      return "---"
    }

    const toggleMute = () => {
      const { mute } = this.state
      if (mute && this.md.mobile() === "iPhone") {
        unlockAudio()
      }

      this.setState({
        ...this.state,
        mute: !mute,
      })
    }
    document.onkeydown = (e) => {
      const isSpace = e.code === " " || e.keyCode === 32
      if (isSpace) {
        handleTap()
        e.preventDefault()
      }
    }

    const {
      btnLoading,
      start,
      devicemotionEnabled,
      toggle,
      mute,
      devicemotionSensitivity,
      displayPopover,
    } = this.state

    return (
      <div className={styles.root}>
        <h6>Tap the button or press spacebar.</h6>
        <h4>Instantaneous BPM</h4>
        <h3>{getInstBPM()}</h3>

        <h4>Average BPM</h4>
        <h3>{getAvgBPM()}</h3>

        <div>
          {!toggle && (
            <Button
              disabled={btnLoading}
              className={styles.tapbtn}
              variant="success"
              size="lg"
              onMouseDown={handleTap}
            >
              TAP
            </Button>
          )}
          {toggle && (
            <Button
              disabled={btnLoading}
              className={styles.tapbtn}
              variant="warning"
              size="lg"
              onMouseDown={handleTap}
            >
              TAP
            </Button>
          )}
        </div>

        <div className={styles.btn}>
          <Button variant="info" onClick={handleReset} disabled={!start}>
            Reset
          </Button>
        </div>

        <div className={styles.btn}>
          {!mute && (
            <Button variant="danger" onClick={toggleMute}>
              Mute
            </Button>
          )}
          {mute && (
            <Button variant="success" onClick={toggleMute}>
              Unmute
            </Button>
          )}
        </div>

        {/* {
                    this.md.mobile() && <p>Tip: use two fingers to tap different sides of the button to prevent touch ghosting.</p>
                } */}
        {this.md.mobile() === "iPhone" && !mute && (
          <p>
            If audio pauses suddenly, please make sure your iPhone mute switch
            is off.
          </p>
        )}
        {this.md.mobile() && !devicemotionEnabled && (
          <div style={{ marginTop: 36 }}>
            <h5>
              On a device with a motion sensor? Try using it instead of tapping!
            </h5>
            <Button disabled={btnLoading} onClick={enableDeviceMotion}>
              Enable device motion
            </Button>

            <p style={{ marginTop: 36 }}>Device motion sensitivity</p>
            <Popover
              getPopupContainer={getMountNode()}
              placement="bottom"
              visible={displayPopover}
              content={
                <div>
                  <h6 style={{ margin: 0 }}>
                    {devicemotionSensitivity.toFixed(0) + "%"}
                  </h6>
                </div>
              }
            >
              <Slider
                axis="x"
                xstep={1}
                xmin={0}
                xmax={100}
                x={devicemotionSensitivity}
                onChange={this.handleSenStateChange}
                onDragEnd={this.onSenDragEnd}
              />
            </Popover>
          </div>
        )}
        {devicemotionEnabled && (
          <div>
            <Button variant="danger" onClick={disableDeviceMotion}>
              Disable device motion
            </Button>
          </div>
        )}
      </div>
    )
  }
}

export default TapToBPM
