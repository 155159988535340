import { message } from "antd"
import { getMessageConfig } from "../../../../containers/DarkModeContainers/CustomMessage"

export class VideoClass {
  video: any
  ready: boolean = false
  ctx: any

  constructor() {
    this.ready = false
  }

  startInit = (video: any) => {
    this.video = video

    this.video.autoplay = true
    this.video.loop = true

    // this.video.setAttribute("id", "videoOutput");
    // this.video.setAttribute("style", "display:none;");
    this.video.width = 240
    this.video.height = 240

    if (navigator.mediaDevices) {
      const constraints: MediaStreamConstraints = {
        video: {
          facingMode: "user",
        },
      }

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream: any) => this.successCallback(stream))
        .catch((e: any) => this.errorCallback(e))
      // navigator.mediaDevices.enumerateDevices().then((devices: any) => {
      // this.gotSources(devices);
      // }).catch(() => {
      // message.config(getMessageConfig());
      // message.error('Something went wrong. Please check if you have approved camera access.');
      // })
    } else {
      message.config(getMessageConfig())
      message.error("Something went wrong.")
    }
  }

  setupCameras = (cameras: Array<any>) => {
    let videoSource = cameras[0].id

    const constraints: MediaStreamConstraints = {
      video: {
        // @ts-ignore
        optional: [
          {
            sourceId: videoSource,
          },
        ],
        facingMode: "user",
      },
    }

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream: any) => this.successCallback(stream))
      .catch((e: any) => this.errorCallback(e))
  }

  successCallback = (stream: any) => {
    if (this.video.mozCaptureStream) {
      this.video.mozSrcObject = stream
    } else {
      // console.log(stream);
      // console.log(typeof stream);
      this.video.srcObject = stream
      // this.video.src = (window.URL && window.URL.createObjectURL(stream)) || stream;
    }
    // document.body.appendChild(this.video);
    this.ready = true
  }

  errorCallback = (e: any) => {
    console.error(e)
    message.config(getMessageConfig())
    message.error("Please allow camera access.")
    this.ready = false
  }

  gotSources = (devices: any) => {
    let cameras: Array<any> = []
    devices.forEach((device: any) => {
      if (device.kind === "videoinput") {
        cameras.push(device)
      }
    })
    this.setupCameras(cameras)
  }

  teardown = (stream: any) => {
    this.ready = false
    this.video = null
    this.ctx = null

    if (stream) {
      // Chrome 47+
      stream.getVideoTracks().forEach((track: any) => {
        if ("stop" in track) {
          track.stop()
        }
      })

      // Chrome 46-
      if ("stop" in stream) {
        stream.stop()
      }
    }

    stream = null
  }
}
