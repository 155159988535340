import * as React from "react"
import { Navbar, Nav } from "react-bootstrap"
import { LinkContainer } from "react-router-bootstrap"
import logo from "../../assets/img/logo.png"
import styles from "./Header.module.css"
import { mobileDetect } from "../../common/MobileDetect"

interface IProps {}

interface IState {
  navExpanded: boolean
}

export class Header extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props)
    this.state = {
      navExpanded: false,
    }
  }

  render() {
    const { navExpanded } = this.state
    const expandNav = () => {
      if (!navExpanded) {
        this.setState({
          ...this.state,
          navExpanded: true,
        })
      }
    }

    const closeNav = () => {
      if (navExpanded) {
        this.setState({
          ...this.state,
          navExpanded: false,
        })
      }
    }

    const getNavElems = () => {
      const md = mobileDetect

      // let iPhone reload so that it resets audio context. What cancer.
      if (md.mobile() === "iPhone") {
        return (
          <Nav>
            <Nav.Link href="/chromatictuner">Chromatic Tuner</Nav.Link>
            <Nav.Link href="/pageturner">Page Turner</Nav.Link>
            <Nav.Link href="/tonegenerator">Tone Generator</Nav.Link>
            <Nav.Link href="/metronome">Metronome</Nav.Link>
            <Nav.Link href="/taptobpm">Tap-to-BPM</Nav.Link>
            <Nav.Link href="/pianotomidi">Piano-To-MIDI</Nav.Link>
            <Nav.Link href="/visualizer">MIDI Visualizer</Nav.Link>
            <Nav.Link href="/conductor">Conductor</Nav.Link>
          </Nav>
        )
      }
      return (
        <Nav>
          <LinkContainer to="/chromatictuner" onClick={closeNav}>
            <Nav.Link>Chromatic Tuner</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/pageturner" onClick={closeNav}>
            <Nav.Link>Page Turner</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/tonegenerator" onClick={closeNav}>
            <Nav.Link>Tone Generator</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/metronome" onClick={closeNav}>
            <Nav.Link>Metronome</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/taptobpm" onClick={closeNav}>
            <Nav.Link>Tap-to-BPM</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/pianotomidi" onClick={closeNav}>
            <Nav.Link>Piano-to-MIDI</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/visualizer" onClick={closeNav}>
            <Nav.Link>MIDI Visualizer</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/conductor" onClick={closeNav}>
            <Nav.Link>Conductor</Nav.Link>
          </LinkContainer>
        </Nav>
      )
    }
    return (
      <Navbar
        fixed="top"
        bg="dark"
        variant="dark"
        expand="lg"
        onToggle={expandNav}
        expanded={navExpanded}
      >
        <Navbar.Brand href="/" className={styles.logoWrapper}>
          <img src={logo} alt="TuneApp" className={styles.logo} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={closeNav} />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav className="mr-auto" />
          {getNavElems()}
        </Navbar.Collapse>
      </Navbar>
    )
  }
}

export default Header
