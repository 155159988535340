import * as React from "react"
import {
  PianoRollSVGVisualizer,
  blobToNoteSequence,
  SoundFontPlayer,
  NoteSequence,
} from "@magenta/music"
import styles from "./Visualizer.module.css"
import { Button, Modal } from "react-bootstrap"
import { message } from "antd"
import { getMessageConfig } from "../../containers/DarkModeContainers/CustomMessage"
import { CustomModalDialog } from "../../containers/DarkModeContainers/CustomModalDialog"
import { Link } from "react-router-dom"
import { requestAnimFrame } from "../../common/RequestAnimFrame"
import { unlockAudio } from "../../common/UnlockAudio"

interface IProps {
  propsMidiFile: any
  propsNoteSequence: any
}

interface IState {
  noteSequence: any
  midiFile: any
  uploading: boolean
  playing: boolean
  svgVisualizer: PianoRollSVGVisualizer | null
  player: any
  paused: boolean
  ready: boolean
  showModal: boolean
  fromPianoToMidi: boolean
}

const defaultProps = {
  propsMidiFile: null as any,
  propsNoteSequence: null as any,
}

export class Visualizer extends React.Component<IProps, IState> {
  public static defaultProps = defaultProps

  constructor(props: any) {
    super(props)
    this.state = {
      noteSequence: null,
      midiFile: null,
      uploading: false,
      playing: false,
      svgVisualizer: null,
      player: null,
      paused: false,
      ready: false,
      showModal: false,
      fromPianoToMidi: false,
    }
  }

  componentDidMount = () => {
    const { propsMidiFile, propsNoteSequence } = this.props
    if (propsMidiFile && propsNoteSequence) {
      this.setState({
        noteSequence: propsNoteSequence,
        midiFile: propsMidiFile,
        fromPianoToMidi: true,
      })
      this.initVisualizerAndPlayer(propsNoteSequence)
    }
  }

  componentWillUnmount = () => {
    const { player } = this.state
    if (player && player.isPlaying()) {
      player.stop()
    }
  }

  initVisualizerAndPlayer = (ns: NoteSequence) => {
    const svg = document.getElementsByTagName("svg")[0] as SVGSVGElement
    if (!svg) {
      requestAnimFrame(() => this.initVisualizerAndPlayer(ns))
      return
    }
    const svgVisualizer: PianoRollSVGVisualizer = new PianoRollSVGVisualizer(
      ns,
      svg
    )
    const callbackObject = {
      run: (note: NoteSequence.Note) => {
        svgVisualizer.redraw(note, true)

        // See if we need to scroll the container.
        // const containerWidth = container.getBoundingClientRect().width;
        // if (currentNotePosition > (container.scrollLeft + containerWidth)) {
        //     container.scrollLeft = currentNotePosition - 20;
        // }
      },
      stop: () => {},
    }
    const player: SoundFontPlayer = new SoundFontPlayer(
      "https://storage.googleapis.com/magentadata/js/soundfonts/sgm_plus",
      undefined,
      undefined,
      undefined,
      callbackObject
    )
    player.loadSamples(ns).then(() => {
      this.setState({
        ready: true,
      })
    })

    this.setState({
      svgVisualizer: svgVisualizer,
      player: player,
    })
  }

  setNoteSequence = (midiFile: Blob) => {
    blobToNoteSequence(midiFile)
      .then((ns) => {
        this.setState({
          noteSequence: ns,
        })
        this.initVisualizerAndPlayer(ns)
      })
      .catch(() => {
        message.config(getMessageConfig())
        message.error("Error: MIDI file corrupted. Please try again.")
      })
  }

  onUpload = (event: any) => {
    this.setState({
      uploading: true,
    })

    const file: Blob = event.target.files[0]
    if (
      !file ||
      !(file instanceof Blob) ||
      !(file.type === "audio/midi" || file.type === "audio/mid")
    ) {
      message.config(getMessageConfig())
      message.error("No or unsupported file uploaded")
      return
    }
    // console.log(file);

    this.setState({
      uploading: false,
      midiFile: file,
    })

    this.setNoteSequence(file as Blob)
  }

  reset = () => {
    const { player, svgVisualizer } = this.state
    if (svgVisualizer) svgVisualizer.clearActiveNotes()
    if (player.isPlaying()) {
      player.stop()
    }
    this.setState({
      noteSequence: null,
      midiFile: null,
      uploading: false,
      playing: false,
      svgVisualizer: null,
      player: null,
      paused: false,
      ready: false,
    })
  }

  play = () => {
    this.setState({
      playing: true,
      paused: false,
    })
    const { player, svgVisualizer } = this.state
    if (!player.isPlaying() && svgVisualizer) {
      unlockAudio()
      player.start(svgVisualizer.noteSequence)
    }
  }

  stop = () => {
    this.setState({
      playing: false,
      paused: false,
    })
    const { player } = this.state
    if (player.isPlaying()) {
      player.stop()
    }
  }

  pause = () => {
    this.setState({
      paused: true,
    })
    this.state.player.pause()
  }

  resume = () => {
    this.setState({
      paused: false,
    })
    unlockAudio()
    this.state.player.resume()
  }

  handleCloseModal = () => {
    this.setState({
      ...this.state,
      showModal: false,
    })
  }

  handleOpenModal = () => {
    this.setState({
      ...this.state,
      showModal: true,
    })
  }

  render() {
    const {
      uploading,
      noteSequence,
      playing,
      paused,
      ready,
      showModal,
      fromPianoToMidi,
    } = this.state
    return (
      <div className={styles.root}>
        {!fromPianoToMidi && (
          <div>
            <h1>MIDI Visualizer</h1>
            <h5>
              <button className={styles.linkbtn} onClick={this.handleOpenModal}>
                Disclaimer
              </button>{" "}
              | Powered by{" "}
              <a
                href="https://www.polyphone-soundfonts.com/en/files/27-instrument-sets/256-sgm-v2-01"
                rel="noopener noreferrer"
                target="_blank"
              >
                SGM
              </a>{" "}
              and{" "}
              <a
                href="https://magenta.tensorflow.org/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Magenta.js
              </a>
            </h5>
          </div>
        )}
        {!noteSequence ? (
          <Button
            variant="warning"
            size="lg"
            disabled={uploading}
            className={styles.btn}
          >
            <label htmlFor="multi" style={{ margin: 0 }}>
              Upload MIDI
            </label>
            <input
              style={{ display: "none" }}
              type="file"
              accept=".mid,.midi"
              id="multi"
              onChange={this.onUpload}
            />
          </Button>
        ) : (
          <div>
            {!fromPianoToMidi && (
              <Button
                variant="warning"
                size="lg"
                onClick={this.reset}
                className={styles.btn}
              >
                Reset
              </Button>
            )}
          </div>
        )}
        {(fromPianoToMidi || noteSequence) && (
          <div className={styles.row}>
            <svg id="svg" className={styles.svg}></svg>
          </div>
        )}
        {noteSequence && (
          <div>
            {!playing ? (
              <Button
                variant="success"
                onClick={this.play}
                className={styles.btn}
                disabled={!ready}
              >
                {ready ? "Play" : "Loading..."}
              </Button>
            ) : (
              <div>
                {paused ? (
                  <Button
                    variant="success"
                    onClick={this.resume}
                    className={styles.btn}
                  >
                    Play
                  </Button>
                ) : (
                  <Button
                    variant="primary"
                    onClick={this.pause}
                    className={styles.btn}
                  >
                    Pause
                  </Button>
                )}
                <Button
                  variant="danger"
                  onClick={this.stop}
                  className={styles.btn}
                >
                  Stop
                </Button>
              </div>
            )}
            {/* {md.mobile() === "iPhone" &&
                            <p>If you don't hear anything, please make sure your iPhone mute switch is off, then reload the page</p>
                        } */}
          </div>
        )}

        <Modal
          dialogAs={CustomModalDialog}
          show={showModal}
          onHide={this.handleCloseModal}
          centered
        >
          <Modal.Body>
            <div className={styles.modal}>
              <h5>
                This MIDI Visualizer is lightweight; it is intended for users to
                visualize and playback MIDI converted using the{" "}
                <Link to="/pianotomidi">Piano-To-MIDI</Link> feature.
              </h5>
              <h5>
                Whilst it can playback almost all MIDIs, there are some known
                limitations:
              </h5>
              <ul>
                <li>No MIDI tempo change support</li>
                <li>No time-scrolling</li>
              </ul>
              <h5>
                Users who wish to use more features should use a DAW; however,
                feel free to submit suggestions/improvements{" "}
                <a href="mailto:l.h.lee2617@gmail.com">here</a>.
              </h5>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="dark" onClick={this.handleCloseModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

export default Visualizer
