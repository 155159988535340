import { mobileDetect } from "./MobileDetect"

export const globalAudioContext = new (window.AudioContext ||
  // @ts-ignore
  window.webkitAudioContext)()

export function unlockAudio() {
  const md = mobileDetect

  if (md.mobile() !== "iPhone") {
    // don't need to unlock for not iPhone
    return
  }

  var buffer = globalAudioContext.createBuffer(1, 1, 22050) // 1/10th of a second of silence
  var source = globalAudioContext.createBufferSource()
  source.buffer = buffer
  source.connect(globalAudioContext.destination)
  source.start()

  // Unlock HTML5 Audio - load a data url of short silence and play it
  // This will allow us to play web audio when the mute toggle is on
  var silenceDataURL: string =
    "data:audio/mp3;base64,//MkxAAHiAICWABElBeKPL/RANb2w+yiT1g/gTok//lP/W/l3h8QO/OCdCqCW2Cw//MkxAQHkAIWUAhEmAQXWUOFW2dxPu//9mr60ElY5sseQ+xxesmHKtZr7bsqqX2L//MkxAgFwAYiQAhEAC2hq22d3///9FTV6tA36JdgBJoOGgc+7qvqej5Zu7/7uI9l//MkxBQHAAYi8AhEAO193vt9KGOq+6qcT7hhfN5FTInmwk8RkqKImTM55pRQHQSq//MkxBsGkgoIAABHhTACIJLf99nVI///yuW1uBqWfEu7CgNPWGpUadBmZ////4sL//MkxCMHMAH9iABEmAsKioqKigsLCwtVTEFNRTMuOTkuNVVVVVVVVVVVVVVVVVVV//MkxCkECAUYCAAAAFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV"
  var tag: HTMLAudioElement = document.createElement("audio")
  tag.controls = false
  tag.preload = "auto"
  tag.loop = false
  tag.src = silenceDataURL
  var p = tag.play()
  if (p)
    p.then(
      function () {
        // console.log("play success")
      },
      function (reason) {
        console.error("play failed", reason)
        // message.config(getMessageConfig());
        // message.error('Something went wrong.');
      }
    )
}

export const decodeAudioData = (buf: ArrayBuffer): Promise<AudioBuffer> => {
  return new Promise((resolve, reject) => {
    globalAudioContext.decodeAudioData(
      buf,
      (d) => resolve(d),
      (e) => {
        reject(e)
      }
    )
  })
}
