import { globalAudioContext, unlockAudio } from "../../common/UnlockAudio"

export class MetronomeWorker {
  metronome: MetronomeClass
  interval: number
  started: boolean
  intervalItem: any

  constructor(metronome: MetronomeClass, interval: number) {
    this.metronome = metronome
    this.interval = interval
    this.started = false
  }

  start() {
    this.intervalItem = setInterval(this.metronome.scheduler, this.interval)
  }

  stop() {
    clearInterval(this.intervalItem)
  }
}

class Note {
  time: number
  currCounter: number

  constructor(time: number, currCounter: number) {
    this.time = time
    this.currCounter = currCounter
  }
}

export class MetronomeClass {
  mainAudioBuf: AudioBuffer
  mainAudioVol: number
  subAudioBuf: AudioBuffer
  subAudioVol: number
  tempo: number
  delay: number // in seconds!
  groups: Array<number>
  nextNoteTime: number = 0
  scheduleAheadTime: number = 0.2
  interval = 25.0 // every 25ms
  currCounter: number = 0 // what to show basically
  groupCounter: number = 0
  notesInQueue: Array<Note> = []
  metronomeWorker: MetronomeWorker
  audioContext: AudioContext
  startTime: number

  constructor(
    mainAudioBuf: AudioBuffer,
    mainAudioVol: number,
    subAudioBuf: AudioBuffer,
    subAudioVol: number,
    tempo: number,
    groups: Array<number>
  ) {
    this.mainAudioBuf = mainAudioBuf
    this.mainAudioVol = mainAudioVol
    this.subAudioBuf = subAudioBuf
    this.subAudioVol = subAudioVol
    this.tempo = tempo
    this.delay = 60.0 / tempo
    this.groups = groups
    this.metronomeWorker = new MetronomeWorker(this, this.interval)
    this.audioContext = globalAudioContext
    this.startTime = 0
  }

  incrCounter() {
    if (!this.groups.length) {
      this.currCounter += 1
    } else {
      if (this.currCounter === this.groups[this.groupCounter]) {
        // fin curr group
        // this.groupEnding = true;
        this.groupCounter += 1
        if (this.groupCounter === this.groups.length) this.groupCounter = 0
        this.currCounter = 1
      } else {
        // this.groupEnding = false;
        // within group
        this.currCounter += 1
      }
    }
  }

  scheduleNote() {
    this.incrCounter()
    this.notesInQueue.push(new Note(this.nextNoteTime, this.currCounter))
    if (this.currCounter === 1) {
      playAudio(this.mainAudioBuf, this.mainAudioVol, this.nextNoteTime)
    } else {
      playAudio(this.subAudioBuf, this.subAudioVol, this.nextNoteTime)
    }
  }

  nextNote() {
    this.nextNoteTime = this.nextNoteTime + this.delay
  }

  scheduler = () => {
    while (
      this.nextNoteTime <
      this.audioContext.currentTime + this.scheduleAheadTime
    ) {
      this.scheduleNote()
      this.nextNote()
    }
  }

  start() {
    unlockAudio()
    this.nextNoteTime = this.audioContext.currentTime
    this.metronomeWorker.start()
    this.startTime = this.nextNoteTime
  }

  stop() {
    this.metronomeWorker.stop()
  }
}

export const playAudio = async (
  audiobuf: AudioBuffer,
  volume: number = 1.0,
  startTime: number = 0
) => {
  let source = globalAudioContext.createBufferSource()
  source.buffer = audiobuf
  let gainNode = globalAudioContext.createGain()
  gainNode.gain.value = volume
  gainNode.connect(globalAudioContext.destination)
  source.connect(gainNode)
  source.start(startTime)
}
