import * as React from "react"
// import page from '../../assets/img/page.png';
import step1 from "../../assets/img/step1.png"
import step2 from "../../assets/img/step2.png"
import step3 from "../../assets/img/step3.png"
import primary from "../../assets/img/primary.png"
import secondary from "../../assets/img/secondary.png"
import { ModuleCard } from "./../../containers/HomePage/Card"
import { Row, Col } from "react-bootstrap"
import { Primary } from "./Primary"
import { Secondary } from "./Secondary"

import styles from "./PageTurner.module.css"

export class PageTurner extends React.Component {
  render() {
    const path: string = window.location.pathname

    if (path === "/pageturner/p") return <Primary />
    if (path.includes("/pageturner/s")) {
      const splitstr: Array<string> = path.split("/")
      const pathId: string =
        splitstr.length > 3 ? splitstr[splitstr.length - 1] : ""
      return <Secondary pathId={pathId} />
    }

    return (
      <div className={styles.root}>
        {/* <img className={styles.mainIcon} src={page} alt=""/> */}
        <h1>Page Turner</h1>
        <h3>Use two devices and gestures to turn pages!</h3>

        <h2>Select your device</h2>
        <Row className={styles.row}>
          <Col>
            <ModuleCard urlkey="pageturner/p" title="Primary" img={primary} />
          </Col>
          <Col>
            <ModuleCard
              urlkey="pageturner/s"
              title="Secondary"
              img={secondary}
            />
          </Col>
        </Row>

        <h2>Instructions</h2>
        <img className={styles.stepIcon} src={step1} alt="" />
        <h4>Step 1</h4>
        <p>
          Prepare a primary device to view sheets, and a secondary device for
          gesture control.
        </p>

        <img className={styles.stepIcon} src={step2} alt="" />
        <h4>Step 2</h4>
        <p>Using second device, scan QR code generated by first device.</p>

        <img className={styles.stepIcon} src={step3} alt="" />
        <h4>Step 3</h4>
        <p>Follow instructions on secondary device to turn pages!</p>
      </div>
    )
  }
}

export default PageTurner
