import * as React from "react"
import { Form, Button, Row, Col } from "react-bootstrap"
import styles from "./ToneGenerator.module.css"
import NumericInput from "react-numeric-input"
import * as Tone from "tone"
import Slider from "react-input-slider"
import { unlockAudio } from "../../common/UnlockAudio"
import { PianoClass } from "./Piano"
import {
  getLocalStorageValue,
  setLocalStorageValue,
} from "../../storage/LocalStorageHandler"
import { getMountNode } from "../../containers/DarkModeContainers/CustomMountNode"
import { Popover } from "antd"
import { mobileDetect } from "../../common/MobileDetect"

class tone {
  public frequency: number = 440.0
  public type: string = "sine"
  public volume: number = -10
}

const notes = ["C", "C#", "D", "D#", "E", "F", "F#", "G", "G#", "A", "A#", "B"]

class Note {
  public noteIdx: number = 9
  public octave: number = 4

  public getFrequency = (a4: number): number => {
    const noteIdxNum: number = this.noteIdx * 1 // JAVASCRIPT IS CANCER
    const octIdx: number = this.octave * 12
    const idx: number = octIdx + noteIdxNum
    const distanceToA4: number = idx - 57
    const freq: number = a4 * Math.pow(Math.pow(2, 1.0 / 12), distanceToA4)
    return freq
  }
}

let osc = new Tone.Oscillator()

interface IProps {}

interface IState {
  mode: string // freq or note
  start: boolean
  tone: tone
  a4: number
  note: Note
  showModal: boolean
  displayPopover: boolean
}

export class ToneGenerator extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props)
    this.state = {
      mode: "Note",
      start: false,
      tone: new tone(),
      a4: 440,
      note: new Note(),
      showModal: false,
      displayPopover: false,
    }
  }

  componentDidMount = () => {
    const localStorageA4Str: string = getLocalStorageValue("A4")
    const localStorageA4: number = parseInt(localStorageA4Str)
    if (this.state.a4 !== localStorageA4) {
      this.setState({
        ...this.state,
        a4: localStorageA4,
      })
    }
  }

  componentWillUnmount = () => {
    osc.stop()
  }

  render() {
    const { mode, start, tone, a4, note, displayPopover } = this.state

    const handleModeChange = (event: any) => {
      if (start) handleStop()
      this.setState({
        ...this.state,
        mode: event.target.value,
        start: false,
      })
    }

    const handleTypeChange = (event: any) => {
      const type: string = event.target.value
      tone.type = type.toLowerCase()
      this.setState({
        ...this.state,
        tone: tone,
      })
      osc.type = type.toLowerCase()
    }

    const handleVolumeStateChange = (newVolume: any) => {
      tone.volume = newVolume.x
      this.setState({
        ...this.state,
        tone: tone,
        displayPopover: true,
      })
    }

    const handleVolumeChange = () => {
      this.setState({
        ...this.state,
        displayPopover: false,
      })
      if (start) {
        handleStart()
      }
    }

    const handleNoteIdxChange = (event: any) => {
      const newNoteIdx: number = event.target.value
      note.noteIdx = newNoteIdx
      tone.frequency = note.getFrequency(a4)
      this.setState({
        ...this.state,
        note: note,
        tone: tone,
      })
      if (start) {
        handleStart()
      }
    }

    const handleNoteOctChange = (event: any) => {
      const newOctave: number = event.target.value
      note.octave = newOctave
      tone.frequency = note.getFrequency(a4)
      this.setState({
        ...this.state,
        note: note,
        tone: tone,
      })
      if (start) {
        handleStart()
      }
    }

    const handleNotePress = (midiNumber: number) => {
      const newNoteIdx: number = midiNumber - 48
      note.noteIdx = newNoteIdx
      tone.frequency = note.getFrequency(a4)
      this.setState({
        ...this.state,
        note: note,
        tone: tone,
      })
      if (start) {
        handleStart()
      }
      playNote()
    }

    const handleOctDown = () => {
      const newOctave: number = note.octave - 1
      note.octave = newOctave
      tone.frequency = note.getFrequency(a4)
      this.setState({
        ...this.state,
        note: note,
        tone: tone,
      })
      if (start) {
        handleStart()
      }
    }

    const handleOctUp = () => {
      const newOctave: number = note.octave + 1
      note.octave = newOctave
      tone.frequency = note.getFrequency(a4)
      this.setState({
        ...this.state,
        note: note,
        tone: tone,
      })
      if (start) {
        handleStart()
      }
    }

    const setFreq = (freq: any) => {
      tone.frequency = freq
      this.setState({
        ...this.state,
        tone: tone,
      })
      if (start) {
        handleStart()
      }
    }

    const setA4 = (freq: any) => {
      this.setState({
        ...this.state,
        a4: freq,
      })
      setLocalStorageValue("A4", freq)
      tone.frequency = note.getFrequency(freq)
    }

    const formatHz1DP = (value: any) => {
      return (value * 1.0).toFixed(1).toString() + " Hz"
    }

    const formatHz = (value: any) => {
      return value + " Hz"
    }

    const handleStart = () => {
      unlockAudio()

      osc.stop()
      this.setState({
        ...this.state,
        start: true,
      })
      osc = new Tone.Oscillator(tone.frequency, tone.type)
      osc.volume.value = tone.volume
      if (osc.volume.value !== -50) {
        osc.toDestination().start()
      }
    }

    const playNote = () => {
      osc.stop()
      unlockAudio()
      osc = new Tone.Oscillator(tone.frequency, tone.type)
      osc.volume.value = tone.volume
      if (osc.volume.value !== -50) {
        osc.toDestination().start()
      }
    }

    const stopNote = () => {
      osc.stop()
      if (start) handleStart()
    }

    const handleStop = () => {
      osc.stop()
      this.setState({
        ...this.state,
        start: false,
      })
    }

    return (
      <div className={styles.root}>
        <Form className={styles.form}>
          <Form.Group controlId="modeTypeForm">
            <Form.Label>
              <h1 className={styles.modeFormTitle}>Mode</h1>
            </Form.Label>
            <Form.Control
              as="select"
              className={styles.modeFormBox}
              value={mode}
              onChange={handleModeChange}
            >
              <option>Note</option>
              <option>Frequency</option>
            </Form.Control>
          </Form.Group>
        </Form>
        <Form className={styles.form}>
          <Form.Group controlId="tonetypeForm">
            <Form.Label>
              <h1 className={styles.modeFormTitle}>Tone type</h1>
            </Form.Label>
            <Form.Control
              as="select"
              className={styles.modeFormBox}
              value={tone.type[0].toUpperCase() + tone.type.substr(1)}
              onChange={handleTypeChange}
            >
              <option>Sine</option>
              <option>Square</option>
              <option>Triangle</option>
              <option>Sawtooth</option>
            </Form.Control>
          </Form.Group>
        </Form>
        {mode === "Frequency" && (
          <div>
            <div className={styles.freqInput}>
              <h1 className={styles.modeFormTitle} style={{ marginBottom: 8 }}>
                Frequency
              </h1>
              <NumericInput
                onChange={setFreq}
                min={20}
                max={20000}
                step={0.1}
                value={tone.frequency}
                strict
                format={formatHz1DP}
              />
            </div>
          </div>
        )}
        {mode === "Note" && (
          <div>
            <div className={styles.freqInput}>
              <h1 className={styles.modeFormTitle} style={{ marginBottom: 8 }}>
                A4
              </h1>
              <NumericInput
                onChange={setA4}
                min={410}
                max={470}
                value={a4}
                disabled={start}
                strict
                format={formatHz}
              />
            </div>
            <Form className={styles.form}>
              <Form.Group controlId="noteTypeForm">
                <Form.Label>
                  <h1 className={styles.modeFormTitle}>Note</h1>
                </Form.Label>
                <Row className={styles.formRow}>
                  <Col className={styles.noteFormBox}>
                    <Form.Control
                      as="select"
                      className={styles.modeFormBox}
                      value={note.noteIdx.toString()}
                      onChange={handleNoteIdxChange}
                    >
                      {notes.map((value, index) => {
                        return (
                          <option key={index} value={index}>
                            {value}
                          </option>
                        )
                      })}
                    </Form.Control>
                  </Col>
                  <Col className={styles.noteFormBox}>
                    <Form.Control
                      as="select"
                      className={styles.modeFormBox}
                      value={note.octave.toString()}
                      onChange={handleNoteOctChange}
                    >
                      {[8, 7, 6, 5, 4, 3, 2, 1, 0].map((value, index) => {
                        return <option key={index}>{value}</option>
                      })}
                    </Form.Control>
                  </Col>
                </Row>
              </Form.Group>
            </Form>
            <PianoClass
              playNote={handleNotePress}
              stopNote={stopNote}
              octUp={handleOctUp}
              octDown={handleOctDown}
              oct={note.octave}
            />
          </div>
        )}
        <div className={styles.volume}>
          <h1 className={styles.modeFormTitle}>Volume</h1>
          <Popover
            getPopupContainer={getMountNode()}
            placement="bottom"
            visible={displayPopover}
            content={
              <div>
                <h6 style={{ margin: 0 }}>
                  {((tone.volume + 50) * 2).toFixed(0) + "%"}
                </h6>
              </div>
            }
          >
            <Slider
              axis="x"
              xstep={1}
              xmin={-50}
              xmax={0}
              x={tone.volume}
              onChange={handleVolumeStateChange}
              onDragEnd={handleVolumeChange}
            />
          </Popover>
        </div>
        <div>
          {!start && (
            <Button size="lg" variant="dark" onClick={handleStart}>
              Start
            </Button>
          )}
          {start && (
            <Button size="lg" variant="danger" onClick={handleStop}>
              Stop
            </Button>
          )}
        </div>

        {mobileDetect.mobile() === "iPhone" && (
          <p style={{ marginTop: 24 }}>
            If audio pauses suddenly, please make sure your iPhone mute switch
            is off.
          </p>
        )}

        {mode === "Note" && (
          <p style={{ marginTop: 48 }}>
            The tone generator uses equal temperament and is limited by your
            device's speakers.
          </p>
        )}

        {mode === "Frequency" && (
          <p style={{ marginTop: 48 }}>
            The tone generator is limited by your device's speakers.
          </p>
        )}
      </div>
    )
  }
}

export default ToneGenerator
