import * as React from "react"
import { Piano, MidiNumbers } from "react-piano"
import DimensionsProvider from "./DimensionsProvider"
import styles from "./Piano.module.css"
import { Button, Row, Col } from "react-bootstrap"

const noteRange = {
  first: MidiNumbers.fromNote("c3"),
  last: MidiNumbers.fromNote("b3"),
}

const ResponsivePiano = (props: any) => {
  return (
    <DimensionsProvider>
      {({
        containerWidth,
        containerHeight,
      }: {
        containerWidth: number
        containerHeight: number
      }) => (
        <Piano
          noteRange={noteRange}
          width={containerWidth}
          height={containerHeight}
          {...props}
        />
      )}
    </DimensionsProvider>
  )
}

interface IProps {
  playNote: any
  stopNote: any
  octUp: any
  octDown: any
  oct: number
}

export class PianoClass extends React.Component<IProps, {}> {
  render = () => {
    const { playNote, stopNote, oct, octDown, octUp } = this.props
    return (
      <div className={styles.root}>
        <Row className={styles.row}>
          <Col xs={2} className={styles.col}>
            <Button
              variant="dark"
              active
              disabled={oct === 0}
              onClick={octDown}
            >
              &#60;
            </Button>
          </Col>
          <Col xs={8} className={styles.col}>
            <div className={styles.pianoRoot}>
              <ResponsivePiano
                className="PianoTheme"
                playNote={playNote}
                stopNote={stopNote}
              />
            </div>
          </Col>
          <Col xs={2} className={styles.col}>
            <Button variant="dark" active disabled={oct === 8} onClick={octUp}>
              &#62;
            </Button>
          </Col>
        </Row>
      </div>
    )
  }
}

export default PianoClass
