interface Window {
  requestAnimationFrame?: any
  webkitRequestAnimationFrame?: any
  mozRequestAnimationFrame?: any
  oRequestAnimationFrame?: any
  msRequestAnimationFrame?: any
  setTimeout?: any
}

declare var window: Window

export const requestAnimFrame = (function () {
  return (
    window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.oRequestAnimationFrame ||
    window.msRequestAnimationFrame ||
    function (callback: any) {
      window.setTimeout(callback, 1000 / 60)
    }
  )
})()
