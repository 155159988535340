import * as React from "react"
import { Card } from "react-bootstrap"
import { Link } from "react-router-dom"
import styles from "./Card.module.css"
import { connect } from "react-redux"

interface IProps {
  urlkey: string
  img: any
  title: string
  darkModeReducer: boolean
}

const defaultProps = {
  darkModeReducer: false,
}

export class ModuleCard extends React.Component<IProps> {
  public static defaultProps = defaultProps

  render() {
    const { urlkey, img, title } = this.props

    const url = "/" + urlkey

    const getCardRootClasses = () => {
      let ret = [styles.cardroot]
      if (this.props.darkModeReducer) {
        ret.push(styles.darkcardroot)
      }
      return ret.join(" ")
    }

    return (
      <div className={styles.root}>
        <Link to={url}>
          <Card className={getCardRootClasses()}>
            <Card.Img variant="top" src={img} className={styles.img} />
            <Card.Body>
              <h1>{title}</h1>
            </Card.Body>
          </Card>
        </Link>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  ...state,
})

export default connect(mapStateToProps)(ModuleCard)
