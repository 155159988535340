import * as React from "react"
import { message, Popconfirm } from "antd"
import { Loading } from "../../Loading"
import { Error } from "../../Error"
import { Button, Table, Modal } from "react-bootstrap"
import styles from "./Primary.module.css"
import { PDFModalDialog } from "../../../containers/PDFModalDialog"
import { PDFViewer } from "./PDFViewer"
import QRCode from "qrcode.react"
import {
  db,
  deleteFromDb,
  writeToDb,
  readFromDb,
  updateDb,
  PageTurnerKey,
} from "../../../firebase"
import {
  getLocalStorageValue,
  setLocalStorageValue,
} from "../../../storage/LocalStorageHandler"
import { getMessageConfig } from "../../../containers/DarkModeContainers/CustomMessage"
import { getMountNode } from "../../../containers/DarkModeContainers/CustomMountNode"
import { sleep } from "../../../utils/sleep"

class PDFObject {
  constructor(file: File, uploadTime: Date) {
    this.file = file
    this.uploadTime = uploadTime
  }
  file: File
  uploadTime: Date
}

interface IProps {}

interface IState {
  files: { [s: string]: PDFObject }
  loading: boolean
  error: boolean
  uploading: boolean
  showPDF: boolean
  showPDFKey: string
  uniqueID: string
  IDLoading: boolean

  // these two are passed as props to PDFViewer
  connected: boolean
  currentPage: number
}

const DBKey: string = "PageTurner"
const key: string = "PageTurnerPDF"
let DBObject: any = null
let unsubscribe: any

export class Primary extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props)
    this.state = {
      files: {},
      loading: true,
      error: false,
      uploading: false,
      showPDF: false,
      showPDFKey: "",
      uniqueID: "",
      IDLoading: true,
      connected: false,
      currentPage: 1,
    }
  }

  componentDidMount = () => {
    /// sleep to prevent DOS
    this.initDBObject()
    this.checkAndSetID(getLocalStorageValue("uniqueID"))

    // TEST!!
    // updateDb(PageTurnerKey, '6969', { active: true });
    // setLocalStorageValue('uniqueID', '6969');
    // this.setState({
    //     uniqueID: '6969',
    //     ...this.state,
    //     IDLoading: false,
    // })
  }

  subscribe = () => {
    const { uniqueID } = this.state
    unsubscribe = db
      .collection(PageTurnerKey)
      .doc(uniqueID)
      .onSnapshot((doc) => {
        if (doc) {
          if (doc.data()) {
            const data = doc.data()
            // @ts-ignore
            const newPage = data.currentPage
            // @ts-ignore
            const newConnected = data.connected

            // console.log(newPage);
            // console.log(newConnected);
            if (newPage !== this.state.currentPage) {
              this.setState({
                ...this.state,
                currentPage: newPage,
              })
            }

            if (this.state.connected && newConnected === false) {
              message.config(getMessageConfig())
              message.error("Secondary device disconnected")
            }

            if (newConnected !== this.state.connected) {
              this.setState({
                ...this.state,
                connected: newConnected,
              })
            }
          }
        }
      })
  }

  checkAndSetID = async (numStr: string) => {
    let docRef = db.collection(PageTurnerKey).doc(numStr)
    /// sleep to prevent abuse, fake delay
    await sleep(1500)
    docRef.get().then((doc) => {
      if (doc.exists) {
        const timeNow = new Date().getTime()

        // if two hours since last read
        readFromDb(PageTurnerKey, numStr).then((doc) => {
          const data = doc.data()
          if (data.write___time && timeNow - data.write___time > 7200000) {
            this.setID(numStr)
          } else {
            this.checkAndSetID(Math.floor(Math.random() * 1000000).toString())
          }
        })
      } else {
        this.setID(numStr)
      }
    })
  }

  setID = (numStr: string) => {
    // must be complete! see firestore rules
    const startingPayload: Object = {
      active: true,
      connected: false,
      filename: "",
      currentPage: 1,
      totalPages: 1,
      dualMode: false,
    }
    writeToDb(PageTurnerKey, numStr, startingPayload)
      .then(() => {
        setLocalStorageValue("uniqueID", numStr)

        this.setState({
          ...this.state,
          uniqueID: numStr,
          IDLoading: false,
        })

        this.subscribe()
      })
      .catch((e: any) => {
        console.error(e)
        message.config(getMessageConfig())
        message.error("Something went wrong.")
        this.setState({
          error: true,
        })
      })
  }

  componentWillUnmount = () => {
    if (unsubscribe) unsubscribe()
    const { uniqueID } = this.state
    deleteFromDb(PageTurnerKey, uniqueID)
  }

  createObjectStore = (db: any, key: string) => {
    const result = db.createObjectStore(key)
    if (!result) {
      this.setState({
        loading: false,
        error: true,
      })
    }
  }

  initDBObject = () => {
    let indexedDB =
        window.indexedDB ||
        // @ts-ignore
        window.webkitIndexedDB ||
        // @ts-ignore
        window.mozIndexedDB ||
        // @ts-ignore
        window.OIndexedDB ||
        // @ts-ignore
        window.msIndexedDB,
      dbVersion = 1.0

    let request = indexedDB.open(DBKey, dbVersion)

    request.onerror = () => {
      this.setState({
        loading: false,
        error: true,
      })
    }

    request.onsuccess = () => {
      DBObject = request.result
      DBObject.onerror = () => {
        this.setState({
          loading: false,
          error: true,
        })
      }

      if (DBObject.setVersion) {
        if (DBObject.version !== dbVersion) {
          var setVersion = DBObject.setVersion(dbVersion)
          setVersion.onsuccess = function () {
            this.createObjectStore(DBObject, key)
            this.downloadFiles()
          }
        } else {
          this.downloadFiles()
        }
      } else {
        this.downloadFiles()
      }
    }

    request.onupgradeneeded = (event) => {
      // @ts-ignore
      this.createObjectStore(event.target.result, key)
    }
  }

  downloadFiles = () => {
    let files: { [s: string]: PDFObject } = {}

    let transaction = DBObject.transaction([key], "readonly")

    transaction.objectStore(key).openCursor().onsuccess = (event: any) => {
      let cursor = event.target.result
      if (cursor) {
        const cursorKey: string = cursor.key
        files[cursorKey] = cursor.value
        cursor.continue()
      } else {
        // let test = window.URL.createObjectURL(files[0]);
        // console.log(test);
        this.setState({
          loading: false,
          uploading: false,
          files: files,
        })
      }
    }
  }

  deleteFile = (filename: string) => {
    let transaction = DBObject.transaction([key], "readwrite")
    transaction.objectStore(key).delete(filename)
    // this.downloadFiles(); // don't redownload, just change state

    let { files } = this.state

    delete files[filename]

    this.setState({
      files: files,
    })
  }

  uploadFiles = (key: string, files: Array<File>) => {
    let transaction = DBObject.transaction([key], "readwrite")
    files.forEach((file: File) => {
      let dataToWrite = new PDFObject(file, new Date())
      // Put the file into the db
      transaction.objectStore(key).put(dataToWrite, file.name)
    })

    this.downloadFiles()
  }

  onUpload = (event: any) => {
    this.setState({
      uploading: true,
    })
    const gottenFiles: Array<File> = Array.from(event.target.files)

    let filesToUpload: Array<File> = []

    gottenFiles.forEach((file: File) => {
      if (file.type !== "application/pdf") {
        message.config(getMessageConfig())
        message.error(<div>Ignored "{file.name}" as it is not a PDF.</div>, 5)
      } else if (Object.keys(this.state.files).includes(file.name)) {
        message.config(getMessageConfig())
        message.error(
          <div>Ignored "{file.name}" as it is already present below.</div>,
          5
        )
      } else {
        filesToUpload.push(file)
      }
    })

    if (!filesToUpload.length) {
      this.setState({
        uploading: false,
      })
    } else {
      this.uploadFiles(key, filesToUpload)
    }
  }

  showPDF = (pdfKey: string) => {
    this.setState({
      ...this.state,
      showPDF: true,
      showPDFKey: pdfKey,
    })
  }

  handleClosePDF = () => {
    this.setState({
      ...this.state,
      showPDF: false,
    })
  }

  getQRCodeURL = () => {
    const { uniqueID } = this.state
    return "https://" + window.location.host + "/pageturner/s/" + uniqueID
  }

  handleDisconnect = () => {
    // if (unsubscribe) unsubscribe();
    const { uniqueID } = this.state

    const payload: Object = {
      connected: false,
    }

    updateDb(PageTurnerKey, uniqueID, payload)
      .then(() => {})
      .catch(() => {
        message.config(getMessageConfig())
        message.error("Something went wrong.")
      })

    this.setState({
      currentPage: 1,
      connected: false,
    })
  }

  writePageToDb = (currentPage: number, totalPages: number) => {
    const { files, showPDFKey, uniqueID } = this.state
    const payload: Object = {
      currentPage: currentPage,
      totalPages: totalPages,
      filename: files[showPDFKey].file.name,
    }

    updateDb(PageTurnerKey, uniqueID, payload)
      .then(() => {})
      .catch(() => {
        message.config(getMessageConfig())
        message.error("Something went wrong.")
      })
  }

  incrPage = (totalPages: number, dualMode: boolean) => {
    const { currentPage } = this.state
    const delta: number = dualMode ? 2 : 1
    this.setState({
      currentPage: currentPage * 1 + delta,
    })
    this.writePageToDb(currentPage * 1 + delta, totalPages)
  }

  decrPage = (totalPages: number, dualMode: boolean) => {
    const { currentPage } = this.state
    const delta: number = dualMode ? 2 : 1
    this.setState({
      currentPage: currentPage * 1 - delta,
    })
    this.writePageToDb(currentPage * 1 - delta, totalPages)
  }

  render() {
    const {
      IDLoading,
      loading,
      error,
      files,
      uploading,
      showPDF,
      showPDFKey,
      uniqueID,
      connected,
      currentPage,
    } = this.state

    if (loading || IDLoading) {
      return <Loading />
    } else if (error) {
      return <Error />
    }

    return (
      <div className={styles.root}>
        {!connected && (
          <div>
            <div>
              <QRCode
                size={192}
                className={styles.qrCode}
                value={this.getQRCodeURL()}
              />
            </div>
            <h6>
              Scan this QR Code or key in the following code in your secondary
              device:
            </h6>
            <h3>{uniqueID}</h3>
          </div>
        )}
        {connected && (
          <div>
            <h2 className={styles.connected}>Connected!</h2>
            <Popconfirm
              getPopupContainer={getMountNode()}
              placement="bottom"
              title="Are you sure you want to disconnect?"
              onConfirm={this.handleDisconnect}
              okText="Yes"
              cancelText="No"
            >
              <Button style={{ marginBottom: 15 }} variant="danger">
                Disconnect
              </Button>
            </Popconfirm>
          </div>
        )}

        <h4>Files</h4>
        <Table responsive="sm" className={styles.table} striped>
          <thead>
            <tr>
              <th>No.</th>
              <th>File Name</th>
              <th>Delete</th>
              <th>View</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(files).map((key, index) => {
              const fileName: string = key
              // const blob: Blob = files[key].blob;
              // const uploadTime: Date = files[key].uploadTime;

              return (
                <tr key={index} className={styles.tr}>
                  <td>{index + 1}</td>
                  <td>{fileName}</td>
                  {/* <td>{uploadTime.toString()}</td> */}
                  <td style={{ padding: 8 }}>
                    <Popconfirm
                      getPopupContainer={getMountNode()}
                      placement="bottom"
                      title="Are you sure you want to delete this?"
                      onConfirm={() => this.deleteFile(key)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button variant="danger" size="sm">
                        Delete
                      </Button>
                    </Popconfirm>
                  </td>
                  <td style={{ padding: 8 }}>
                    <Button
                      variant="success"
                      size="sm"
                      onClick={() => this.showPDF(key)}
                    >
                      View
                    </Button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
        <Button
          className={styles.uploadBtn}
          variant="warning"
          size="lg"
          disabled={uploading}
        >
          <label htmlFor="multi" style={{ margin: 0 }}>
            Upload PDFs
          </label>
          <input
            style={{ display: "none" }}
            type="file"
            accept="application/pdf"
            id="multi"
            onChange={this.onUpload}
            multiple
          />
        </Button>

        <p>Disclaimer: all PDFs are stored locally on your device.</p>
        <Modal
          dialogAs={PDFModalDialog}
          show={showPDF}
          onHide={this.handleClosePDF}
          centered
        >
          {files[showPDFKey] && (
            <PDFViewer
              file={files[showPDFKey].file}
              uniqueID={uniqueID}
              currentPage={currentPage}
              connected={connected}
              incrPage={(totalPages: number, dualMode: boolean) =>
                this.incrPage(totalPages, dualMode)
              }
              decrPage={(totalPages: number, dualMode: boolean) =>
                this.decrPage(totalPages, dualMode)
              }
              writePageToDb={(currentPage: number, totalPages: number) =>
                this.writePageToDb(currentPage, totalPages)
              }
              handleDisconnect={() => this.handleDisconnect()}
            />
          )}
        </Modal>
      </div>
    )
  }
}

export default Primary
