import * as React from "react"
import { TuningInfo, AudioProcessor } from "./Classes"
import NumericInput from "react-numeric-input"
import { Button, ProgressBar, Modal } from "react-bootstrap"
import styles from "./TunerInterface.module.css"
import Slider from "react-input-slider"
import { connect } from "react-redux"
import { CustomModalDialog } from "../../../containers/DarkModeContainers/CustomModalDialog"
import {
  getLocalStorageValue,
  setLocalStorageValue,
} from "../../../storage/LocalStorageHandler"
import { message, Popover } from "antd"
import { getMessageConfig } from "../../../containers/DarkModeContainers/CustomMessage"
import { getMountNode } from "../../../containers/DarkModeContainers/CustomMountNode"
import { sleep } from "../../../utils/sleep"
import { mobileDetect } from "../../../common/MobileDetect"

interface IProps {
  darkModeReducer: boolean
}

interface IState {
  currTuningInfo: TuningInfo
  playLouder: boolean
  start: boolean
  volume: number
  micAccess: boolean
  modalMsg: string
  showModal: boolean
  micLevel: number
  windowWidth: number
  windowHeight: number
  currPercentage: number
  displayPopover: boolean
}

const defaultProps = {
  darkModeReducer: false,
}

let currA: number = 440
let ap: AudioProcessor
let element: any

const storedDarkMode: boolean =
  getLocalStorageValue("darkModeReducer") === "true"

// Properties of the gauge
let gaugeOptions = {
  hasNeedle: true,
  needleColor: storedDarkMode ? "white" : "black",
  needleUpdateSpeed: 100, // 200ms to match handleListen wait
  arcColors: ["#FF5F6D", "#ffc048", "#0be881", "#ffc048", "#FF5F6D"],
  arcOverEffect: false,
  arcDelimiters: [22.5, 45, 55, 77.5],
  needleStartValue: 50,
}

let gaugeWidth: number = 500

export class TunerInterface extends React.Component<IProps, IState> {
  public static defaultProps = defaultProps

  constructor(props: any) {
    super(props)
    this.state = {
      currTuningInfo: new TuningInfo(0, 0, "", 0),
      playLouder: true,
      start: false,
      volume: 0,
      micAccess: false,
      modalMsg: "Something went wrong...",
      showModal: false,
      micLevel: parseInt(getLocalStorageValue("micLevel")),
      windowWidth: 1080,
      windowHeight: 1920,
      currPercentage: 50,
      displayPopover: false,
    }
  }

  updateWindowDimensions = (event: any) => {
    const { currPercentage } = this.state
    const { darkModeReducer } = this.props

    if (event && event.type === "click") {
      if (darkModeReducer && gaugeOptions.needleColor === "white") return
      if (!darkModeReducer && gaugeOptions.needleColor === "black") return

      if (darkModeReducer) {
        gaugeOptions.needleColor = "white"
      } else {
        gaugeOptions.needleColor = "black"
      }
    }

    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    })

    element.innerHTML = ""

    gaugeWidth =
      window.innerWidth > 600
        ? window.innerWidth * 0.4
        : window.innerWidth * 0.9

    // @ts-ignore
    GaugeChart.gaugeChart(element, gaugeWidth, gaugeOptions).updateNeedle(
      currPercentage
    )
  }

  componentDidMount = () => {
    const localStorageA4Str: string = getLocalStorageValue("A4")
    currA = localStorageA4Str.length ? parseInt(localStorageA4Str) : 440
    element = document.querySelector("#gaugeArea")
    this.updateWindowDimensions(null)
    window.addEventListener("resize", this.updateWindowDimensions)
    window.addEventListener("click", this.updateWindowDimensions)
  }

  componentWillUnmount = () => {
    this.setState({
      ...this.state,
      start: false,
      volume: 0,
      currTuningInfo: new TuningInfo(0, 0, "", 0),
      micAccess: false,
      modalMsg: "Something went wrong...",
      showModal: false,
    })
    if (ap) ap.teardown()
    window.removeEventListener("resize", this.updateWindowDimensions)
    window.removeEventListener("click", this.updateWindowDimensions)
  }

  render() {
    const {
      currTuningInfo,
      volume,
      micAccess,
      modalMsg,
      showModal,
      micLevel,
      displayPopover,
    } = this.state

    const updatePercentage = () => {
      element.innerHTML = ""

      const newPercentage = 50.0 + this.state.currTuningInfo.detune

      if (this.props.darkModeReducer) {
        gaugeOptions.needleColor = "white"
      } else {
        gaugeOptions.needleColor = "black"
      }

      // @ts-ignore
      GaugeChart.gaugeChart(element, gaugeWidth, gaugeOptions).updateNeedle(
        newPercentage
      )
      gaugeOptions.needleStartValue = newPercentage
      // console.log(newPercentage);
    }

    const handleStart = () => {
      if (
        mobileDetect.mobile() === "iPhone" &&
        mobileDetect.userAgent() !== "Safari"
      ) {
        message.config(getMessageConfig())
        message.error("Unsupported browser. Please use Safari on your iPhone.")
        return
      }
      ap = new AudioProcessor(currA, this.state.micLevel) // constructs and readies the Audio Processor
      ap.init()
      this.setState({
        ...this.state,
        start: true,
      })
      requestAnimationFrame(handleListen)
    }

    const handleStop = () => {
      this.setState({
        ...this.state,
        start: false,
        volume: 0,
        currTuningInfo: new TuningInfo(0, 0, "", 0),
        showModal: false,
      })
      gaugeOptions.needleStartValue = 50
      if (ap) ap.teardown()
    }

    const getNote = () => {
      if (currTuningInfo.octave === 0 && currTuningInfo.note === "") {
        return "Ready"
      }
      return this.state.currTuningInfo.note + this.state.currTuningInfo.octave
    }

    const handleListen = async () => {
      if (this.state.start) {
        const nextTuningInfo = ap.tuningInfo
        const nextPlayLouder = ap.playLouder
        const nextVolume = ap.volume
        const nextMicAccess = ap.micAccess
        const initComplete = ap.initComplete

        if (nextTuningInfo.frequency !== currTuningInfo.frequency) {
          this.setState({
            ...this.state,
            currTuningInfo: nextTuningInfo,
          })
          updatePercentage()
          // only set to false when found next pitch
          if (this.state.playLouder) {
            this.setState({
              ...this.state,
              playLouder: false,
            })
          }
        }

        if (!this.state.playLouder && nextPlayLouder) {
          this.setState({
            ...this.state,
            playLouder: true,
          })
        }

        if (volume !== nextVolume) {
          this.setState({
            ...this.state,
            volume: nextVolume,
          })
        }

        if (micAccess !== nextMicAccess) {
          this.setState({
            ...this.state,
            micAccess: nextMicAccess,
          })
        }

        if (initComplete && !nextMicAccess && !ap.microphone) {
          handleStop()
          // this.setState({
          //     showModal: true,
          //     modalMsg: "Please allow microphone access."
          // });
          // message.config(getMessageConfig());
          // message.error("Please allow microphone access.");
        } else {
          // sleep for 100ms for smoother data
          await sleep(100)
          requestAnimationFrame(handleListen)
        }
      }
    }

    const setA = (valueAsNumber: number) => {
      setLocalStorageValue("A4", valueAsNumber.toString())
      currA = valueAsNumber
    }

    const parseVolume = (volume: number) => {
      const parsedVolume: number = volume > 100 ? 100 : volume
      return parsedVolume
    }

    const getVariant = (parsedVolume: number) => {
      if (parsedVolume > 50) return "success"
      if (parsedVolume > 25) return "warning"
      return "danger"
    }

    const handleCloseModal = () => {
      this.setState({
        ...this.state,
        showModal: false,
      })
    }

    const getCaption = () => {
      const detune: number = currTuningInfo.detune
      if (this.state.start && this.state.playLouder && this.state.micAccess) {
        return "Play louder"
      } else if (!this.state.start) {
        return "Stopped"
      } else if (detune < -2) {
        return "Tune up"
      } else if (detune > 2) {
        return "Tune down"
      } else {
        return "OK"
      }
    }

    const handleMicLevStateChange = (newMicLevel: any) => {
      setLocalStorageValue("micLevel", newMicLevel.x.toString())
      this.setState({
        ...this.state,
        micLevel: newMicLevel.x,
        displayPopover: true,
      })
    }

    const handleMicLevChange = () => {
      this.setState({
        ...this.state,
        displayPopover: false,
      })
      if (this.state.start) {
        handleStop()
        handleStart()
      }
    }

    return (
      <div className={styles.root}>
        <div className={styles.numericInputWrapper}>
          <div className={styles.input}>
            A4 ={" "}
            <NumericInput
              onChange={setA}
              min={410}
              max={470}
              value={currA}
              strict
              disabled={this.state.start}
            />{" "}
            Hz
          </div>
        </div>

        <div className={styles.volume}>
          <h1 className={styles.modeFormTitle}>Microphone Level</h1>
          <Popover
            getPopupContainer={getMountNode()}
            placement="bottom"
            visible={displayPopover}
            content={
              <div>
                <h6 style={{ margin: 0 }}>{micLevel.toString() + "%"}</h6>
              </div>
            }
          >
            <Slider
              axis="x"
              xstep={1}
              xmin={0}
              xmax={100}
              x={micLevel}
              onChange={handleMicLevStateChange}
              onDragEnd={handleMicLevChange}
              disabled={this.state.start}
            />
          </Popover>
        </div>

        <div>
          {!this.state.start && (
            <Button size="lg" variant="dark" onClick={handleStart}>
              Start
            </Button>
          )}
          {this.state.start && (
            <Button size="lg" variant="danger" onClick={handleStop}>
              Stop
            </Button>
          )}
        </div>

        <h1 className={styles.buttonHeight}>{getNote()}</h1>
        {/* <div className={styles.gauge}> */}
        <div className={styles.gauge} id="gaugeArea"></div>
        {/* <GaugeChart id="gauge-chart5"
                        nrOfLevels={420}
                        arcsLength={[0.2, 0.2, 0.1, 0.2, 0.2]}
                        colors={['#FF5F6D', '#ffc048', '#0be881', '#ffc048', '#FF5F6D']}
                        percent={getPercentage(currTuningInfo.detune)}
                        arcPadding={0.02}
                        animate={false}
                        hideText
                    /> */}

        {/* </div> */}
        <h1>
          {this.state.currTuningInfo.frequency
            ? this.state.currTuningInfo.frequency
            : "-- "}
          Hz
        </h1>
        <ProgressBar
          className={styles.progressBar}
          variant={getVariant(parseVolume(volume))}
          now={parseVolume(volume)}
        />
        <h1 style={{ marginBottom: 12 }}>{getCaption()}</h1>
        <a
          href="https://miracle.otago.ac.nz/tartini/papers/A_Smarter_Way_to_Find_Pitch.pdf"
          rel="noopener noreferrer"
          target="_blank"
        >
          Tuner algorithm
        </a>

        <p style={{ marginTop: 24 }}>
          The tuner uses equal temperament and is limited by your device's
          microphone.
        </p>

        <Modal
          dialogAs={CustomModalDialog}
          show={showModal}
          onHide={handleCloseModal}
          centered
        >
          <Modal.Body>
            <h3>{modalMsg}</h3>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="dark" onClick={handleCloseModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  ...state,
})

export default connect(mapStateToProps)(TunerInterface)
