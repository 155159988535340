import React from "react"
import { connect } from "react-redux"
import "./App.css"
import "antd/dist/antd.css"
import "react-piano/dist/styles.css"
import { Header } from "./containers/Header"
import { AppContent } from "./containers/AppContent"
import { Footer } from "./containers/Footer"
import { Router } from "react-router-dom"
import styles from "./App.module.css"
import { history } from "./utils"
import "./styles/DarkModal.css"
// import { Ad } from './components/Ad';

interface IProps {
  darkModeReducer: boolean
}

const defaultProps: IProps = {
  darkModeReducer: false,
}

interface IState {}

export class App extends React.Component<IProps, IState> {
  public static defaultProps = defaultProps

  shouldComponentUpdate = (nextProps: any) => {
    if (this.props.darkModeReducer !== nextProps.darkModeReducer) {
      return true
    }
    return false
  }

  getRootStyle = () => {
    if (this.props.darkModeReducer) {
      return "darkroot"
    }

    return "lightroot"
  }

  render() {
    return (
      <div className={this.getRootStyle()}>
        <Router history={history}>
          <Header />
          <div className={styles.AppContent}>
            <AppContent />
          </div>
          {/* <Ad /> */}
          <Footer />
        </Router>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  ...state,
})

export default connect(mapStateToProps)(App)
