import * as React from "react"
import { TunerInterface } from "./TunerInterface"
import { Modal, Button } from "react-bootstrap"
import { CustomModalDialog } from "../../containers/DarkModeContainers/CustomModalDialog"
import { message } from "antd"
import { getMessageConfig } from "../../containers/DarkModeContainers/CustomMessage"
import { mobileDetect } from "../../common/MobileDetect"

interface IProps {}

interface IState {
  showModal: boolean
  modalMsg: string
}

export class ChromaticTuner extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props)
    this.state = {
      showModal: false,
      modalMsg: "",
    }
  }

  componentDidMount = () => {
    const md = mobileDetect

    if (md.mobile() === "iPhone") {
      if (md.userAgent() !== "Safari") {
        // this.setState({
        //     ...this.state,
        //     showModal: true,
        //     modalMsg: "The tuner only works in Safari on your iPhone. Please use Safari.",
        // });
        message.config(getMessageConfig())
        message.error(
          "The tuner only works in Safari on your iPhone. Please use Safari."
        )
      }
    }
  }

  render() {
    const { showModal, modalMsg } = this.state

    const handleCloseModal = () => {
      this.setState({
        ...this.state,
        showModal: false,
      })
    }

    return (
      <div>
        <TunerInterface />
        <Modal
          dialogAs={CustomModalDialog}
          show={showModal}
          onHide={handleCloseModal}
          centered
        >
          <Modal.Body>
            <h3>{modalMsg}</h3>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="dark" onClick={handleCloseModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

export default ChromaticTuner
