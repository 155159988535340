import * as React from "react"
import { Row, Col, Button, Form } from "react-bootstrap"
import { updateDb, db, PageTurnerKey } from "../../../firebase"
import { message, Popconfirm } from "antd"
import styles from "./Secondary.module.css"
import { GestureControl } from "./GestureControl.tsx"
import { getMessageConfig } from "../../../containers/DarkModeContainers/CustomMessage"
import { getMountNode } from "../../../containers/DarkModeContainers/CustomMountNode"
import { sleep } from "../../../utils/sleep"
import { Loading } from "../../Loading"

interface IProps {
  pathId: string
}

interface IState {
  currentPage: number
  totalPages: number
  uniqueID: string
  active: boolean
  filename: string
  dualMode: boolean
  loading: boolean
}

let unsubscribe: any = null

export class Secondary extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props)
    this.state = {
      currentPage: 1,
      totalPages: 1,
      uniqueID: "",
      active: false,
      filename: "",
      dualMode: false,
      loading: true,
    }
  }

  componentDidMount = () => {
    const { pathId } = this.props

    if (pathId.length) {
      this.connectWithID(pathId)
    } else {
      this.setState({
        loading: false,
      })
    }

    document.onkeydown = (e) => {
      const arrowRight = e.key === "ArrowRight" || e.keyCode === 39
      const arrowLeft = e.key === "ArrowLeft" || e.keyCode === 37
      if (arrowRight && this.increaseAllowed()) {
        this.incrPage()
      } else if (arrowLeft && this.decreaseAllowed()) {
        this.decrPage()
      }
    }
  }

  componentWillUnmount = () => {
    if (unsubscribe) unsubscribe()

    if (this.state.active) {
      let { currentPage, totalPages, filename, uniqueID } = this.state

      if (!currentPage) currentPage = 1
      if (!totalPages) totalPages = 1
      if (!filename) filename = ""

      const payload: Object = {
        connected: false,
      }

      updateDb(PageTurnerKey, uniqueID, payload)
        .then(() => {})
        .catch(() => {
          message.config(getMessageConfig())
          message.error("Something went wrong.")
        })
    }
    document.onkeydown = null
  }

  writePageToDB = (currentPage: number) => {
    const { uniqueID } = this.state

    const payload: Object = {
      currentPage: currentPage,
      connected: true,
    }

    updateDb(PageTurnerKey, uniqueID, payload)
      .then(() => {})
      .catch(() => {
        message.config(getMessageConfig())
        message.error("Something went wrong.")
      })
  }

  incrPage = () => {
    const { currentPage, dualMode } = this.state
    // doubleCheck for gesture
    if (!this.increaseAllowed()) return
    const delta: number = dualMode ? 2 : 1
    this.writePageToDB(currentPage * 1 + delta)
  }

  decrPage = () => {
    const { currentPage, dualMode } = this.state
    // doubleCheck
    if (!this.decreaseAllowed()) return
    const delta: number = dualMode ? 2 : 1
    this.writePageToDB(currentPage * 1 - delta)
  }

  connectWithID = async (numStr: string) => {
    /// sleep to prevent abuse, fake delay
    await sleep(1500)

    let docRef = db.collection(PageTurnerKey).doc(numStr)
    docRef
      .get()
      .then((doc) => {
        // @ts-ignore
        if (doc.exists && !doc.data().connected) {
          // SAFETY: won't have two connected devices

          // set connected to true
          const payload: Object = {
            connected: true,
          }

          updateDb(PageTurnerKey, numStr, payload)
            .then(() => {})
            .catch(() => {
              message.config(getMessageConfig())
              message.error("Something went wrong.")
            })

          unsubscribe = db
            .collection(PageTurnerKey)
            .doc(numStr)
            .onSnapshot((doc: any) => {
              const data = doc ? doc.data() : null

              if (data) {
                this.setState({
                  uniqueID: numStr,
                })
                if (data.active) {
                  if (data.active !== this.state.active) {
                    this.setState({
                      active: data.active,
                    })
                  }
                  if (data.currentPage !== this.state.currentPage) {
                    this.setState({
                      currentPage: data.currentPage,
                    })
                  }
                  if (data.totalPages !== this.state.totalPages) {
                    this.setState({
                      totalPages: data.totalPages,
                    })
                  }
                  if (data.filename !== this.state.filename) {
                    this.setState({
                      filename: data.filename,
                    })
                  }
                  if (data.dualMode !== this.state.dualMode) {
                    this.setState({
                      dualMode: data.dualMode,
                    })
                  }

                  // master sent disconnect
                  if (data.connected === false) {
                    message.config(getMessageConfig())
                    message.error("Primary device disconnected")
                    this.handleDisconnect()
                    return
                  }
                } else {
                  this.setState({
                    active: false,
                  })
                }
              } else {
                this.setState({
                  active: false,
                })
              }
            })
        } else {
          message.config(getMessageConfig())
          message.error(
            "Unknown ID, please refresh your primary device if problem persists",
            5
          )
        }
      })
      .then(() => {
        this.setState({ loading: false })
      })
  }

  handleSubmitID = (event: any) => {
    event.preventDefault()
    this.setState({ loading: true })
    const gottenId: number = event.currentTarget.uniqueIDForm.value
    if (!gottenId) {
      message.config(getMessageConfig())
      message.error("Key in your code on your primary device.")
      this.setState({ loading: false })
    } else {
      const numStr = gottenId.toString()
      this.connectWithID(numStr)
    }
  }

  handleDisconnect = () => {
    if (unsubscribe) unsubscribe()
    const { uniqueID } = this.state

    const payload: Object = {
      connected: false,
    }

    updateDb(PageTurnerKey, uniqueID, payload)
      .then(() => {})
      .catch(() => {
        message.config(getMessageConfig())
        message.error("Something went wrong.")
      })

    this.setState({
      currentPage: 1,
      totalPages: 1,
      uniqueID: "",
      active: false,
      filename: "",
    })
  }

  getCurrentPageLabel = (): string => {
    const { dualMode, totalPages, currentPage } = this.state
    if (dualMode && currentPage !== totalPages) {
      return currentPage.toString() + "," + (currentPage + 1).toString()
    }
    return currentPage.toString()
  }

  decreaseAllowed = (): boolean => {
    const { currentPage } = this.state
    return currentPage > 1
  }

  increaseAllowed = (): boolean => {
    const { currentPage, dualMode, totalPages } = this.state
    return !(
      currentPage === totalPages ||
      (dualMode && currentPage % 2 === 1 && currentPage + 1 === totalPages)
    )
  }

  render() {
    const { totalPages, active, filename, loading } = this.state
    if (loading) {
      return <Loading />
    }

    // ask to open camera to input the primaryKey
    return (
      <div className={styles.root}>
        {!active && (
          <Form onSubmit={this.handleSubmitID}>
            <Form.Group controlId="uniqueIDForm">
              <Form.Label>
                <h4>
                  Key in the number or scan the QR code on your primary device.
                </h4>
              </Form.Label>
              <Form.Control
                type="number"
                min="1"
                max="999999"
                placeholder="Code"
              />
            </Form.Group>
            <Button type="submit" variant="success">
              Connect
            </Button>
          </Form>
        )}
        {active && <h2 className={styles.connected}>Connected!</h2>}
        {active && (
          <Popconfirm
            getPopupContainer={getMountNode()}
            placement="bottom"
            title="Are you sure you want to disconnect?"
            onConfirm={this.handleDisconnect}
            okText="Yes"
            cancelText="No"
          >
            <Button style={{ marginBottom: 15 }} variant="danger">
              Disconnect
            </Button>
          </Popconfirm>
        )}
        {active && !filename && (
          <h4>Open a file on your primary device to begin.</h4>
        )}
        {active && filename && (
          <div>
            <h5>{filename}</h5>
            <h4>
              {this.getCurrentPageLabel()} / {totalPages}
            </h4>

            <Row className="justify-content-center">
              <Col className={styles.col} xs="auto">
                <Button
                  className={styles.btn}
                  variant="warning"
                  active
                  disabled={!this.decreaseAllowed()}
                  onClick={this.decrPage}
                >
                  <h1>&#60;</h1>
                </Button>
              </Col>
              <Col className={styles.col} xs="auto">
                <Button
                  className={styles.btn}
                  variant="warning"
                  active
                  disabled={!this.increaseAllowed()}
                  onClick={this.incrPage}
                >
                  <h1>&#62;</h1>
                </Button>
              </Col>
            </Row>

            <p style={{ marginBottom: 15, marginTop: 40 }}>
              Motion detection requires camera access. Your data is processed
              locally and not sent to TuneApp.co servers.
            </p>
            <GestureControl
              leftCallBack={this.decrPage}
              rightCallBack={this.incrPage}
            />
          </div>
        )}
      </div>
    )
  }
}

export default Secondary
