const hopSize = 440
let waveform: HTMLCanvasElement | undefined
let beatLines: HTMLCanvasElement | undefined
let secPerPx = hopSize / 44100

export const updateShift = async (playbackTime: number) => {
  if (waveform && beatLines) {
    const container = document.getElementById(`waveformContainer`)
    if (!container) {
      throw new Error(`Can't get waveform container`)
    }
    const shift =
      Math.round(container.clientWidth / 2 + (playbackTime / secPerPx) * -1) +
      `px`
    waveform.style.left = shift
    beatLines.style.left = shift
  } else {
    console.error(`Waveform and beatlines not initialised!`)
  }
}

export const clearWaveformContainer = async () => {
  const c = document.getElementById(`waveformContainer`)
  waveform = undefined
  beatLines = undefined
  if (c) {
    c.innerHTML = ``
    c.style.height = `0px`
  }
}

export const calcAndDrawWaveform = async (
  waveData: number[],
  beats: number[],
  sampleRate: number
) => {
  await clearWaveformContainer()
  secPerPx = hopSize / sampleRate
  const container = document.getElementById(`waveformContainer`)
  if (!container) {
    throw new Error(`Can't get waveform container`)
  }
  container.style.height = `200px`

  waveform = document.createElement(`canvas`)
  container.appendChild(waveform)

  const ctx = waveform.getContext(`2d`)
  if (!ctx) {
    throw new Error(`Can't get waveform container context`)
  }

  const waveformWidth = Math.round(waveData.length / hopSize)

  const height = 200
  const halfHeight = height / 2

  const length = waveData.length
  const step = Math.round(length / waveformWidth)

  waveform.width = waveformWidth
  waveform.height = height
  waveform.style.width = waveformWidth + `px`
  waveform.style.height = height + `px`
  waveform.style.left = Math.round(container.clientWidth / 2) + `px`

  var x = 0,
    sumPositive = 0,
    sumNegative = 0,
    maxPositive = 0,
    maxNegative = 0,
    kNegative = 0,
    kPositive = 0,
    drawIdx = step
  for (var i = 0; i < length; i++) {
    if (i === drawIdx) {
      var p1 = maxNegative * halfHeight + halfHeight
      ctx.strokeStyle = "#fab1a0"
      ctx.strokeRect(x, p1, 1, maxPositive * halfHeight + halfHeight - p1)

      var p2 = (sumNegative / kNegative) * halfHeight + halfHeight
      ctx.strokeStyle = "#e17055"
      ctx.strokeRect(
        x,
        p2,
        1,
        (sumPositive / kPositive) * halfHeight + halfHeight - p2
      )
      x++
      drawIdx += step
      sumPositive = 0
      sumNegative = 0
      maxPositive = 0
      maxNegative = 0
      kNegative = 0
      kPositive = 0
    } else {
      if (waveData[i] < 0) {
        sumNegative += waveData[i]
        kNegative++
        if (maxNegative > waveData[i]) maxNegative = waveData[i]
      } else {
        sumPositive += waveData[i]
        kPositive++
        if (maxPositive < waveData[i]) maxPositive = waveData[i]
      }
    }
  }

  await drawMiddleLine(container, height)
  await drawBeatLines(container, waveformWidth, height, beats)
}

const drawMiddleLine = async (container: HTMLElement, height: number) => {
  const midLine = document.createElement(`canvas`)
  container.appendChild(midLine)

  const ctx = midLine.getContext(`2d`)
  if (!ctx) {
    throw new Error(`Can't get middle line container context`)
  }

  midLine.width = 1
  midLine.height = height
  midLine.style.width = `2px`
  midLine.style.height = `${height}px`
  midLine.style.left = Math.round(container.clientWidth / 2) + `px`

  ctx.strokeStyle = `#ff0000`

  ctx.strokeRect(0, 0, 2, height)
}

const drawBeatLines = async (
  container: HTMLElement,
  width: number,
  height: number,
  beats: number[]
) => {
  const beatsPx = beats.map((t) => Math.round(t / secPerPx))

  beatLines = document.createElement(`canvas`)
  container.appendChild(beatLines)

  const ctx = beatLines.getContext(`2d`)
  if (!ctx) {
    throw new Error(`Can't get beat lines container context`)
  }

  beatLines.width = width
  beatLines.height = height
  beatLines.style.width = width * 1 + `px`
  beatLines.style.height = height * 1 + `px`
  beatLines.style.left = Math.round(container.clientWidth / 2) + `px`

  ctx.strokeStyle = `#b0b0b0`

  beatsPx.map((b) => ctx.strokeRect(b, 0, 0.5, height))
}

export {}
