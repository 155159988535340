import { createStore, applyMiddleware } from "redux"
import thunk from "redux-thunk"
import rootReducer from "./reducers/rootReducer"
import { getLocalStorageValue } from "./storage/LocalStorageHandler"

const initialDarkMode =
  getLocalStorageValue("darkModeReducer") === "true" ? true : false

const initialState: Object = {
  darkModeReducer: initialDarkMode,
}

export default function configureStore() {
  return createStore(rootReducer, initialState, applyMiddleware(thunk))
}
