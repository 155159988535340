import { sleep } from "../utils/sleep"
import { requestAnimFrame } from "./RequestAnimFrame"
// calls callback when motion is more than a set sensitivity
// returns a dispose function that clears the interval set and the event listener
export const startShakeDetection = async (
  callback: () => Promise<any>,
  threshold = 20,
  // eslint-disable-next-line no-unused-vars
  debug: undefined | ((change: number) => any) = undefined
) => {
  let x1 = 0,
    y1 = 0,
    z1 = 0,
    x2 = 0,
    y2 = 0,
    z2 = 0
  let active = true

  const handleMotionEvent = (e: DeviceMotionEvent) => {
    x1 = e.accelerationIncludingGravity?.x ?? x1
    y1 = e.accelerationIncludingGravity?.y ?? y1
    z1 = e.accelerationIncludingGravity?.z ?? z1
  }

  window.addEventListener(`devicemotion`, handleMotionEvent, false)

  const computeShake = async () => {
    console.log(`compute`)
    const change = Math.abs(x1 - x2 + y1 - y2 + z1 - z2)
    if (change > threshold) {
      // if (debug) debug(change + 100000)
      callback()
      await sleep(200) // sleep 200ms after detection
    }
    // if (debug) debug(change)

    x2 = x1
    y2 = y1
    z2 = z1
    if (active) {
      // await sleep(100)
      requestAnimFrame(computeShake)
    }
  }

  requestAnimFrame(computeShake)

  return () => {
    active = false
    window.removeEventListener(`deviceMotion`, handleMotionEvent as any, false)
  }
}

// throw exception if not granted
export const requestDeviceMotionIfNeeded = async () => {
  if (typeof DeviceMotionEvent.requestPermission === "function") {
    const permState = await DeviceMotionEvent.requestPermission()
    if (permState !== `granted`) {
      throw new Error(`Please grant device motion permissions`)
    }
  }
}
