import React, { useState, useRef, useEffect } from "react"
import { Link } from "react-router-dom"
import styles from "../Conductor.module.css"
import { Button } from "react-bootstrap"
import { message, Popover } from "antd"
import { getMessageConfig } from "../../../containers/DarkModeContainers/CustomMessage"
// @ts-ignore
import * as MusicTempo from "music-tempo"
import { Loading } from "../../Loading"
import {
  calcAndDrawWaveform,
  clearWaveformContainer,
  updateShift,
} from "./CanvasHelper"
import { requestAnimFrame } from "../../../common/RequestAnimFrame"
import {
  setLocalStorageValue,
  getLocalStorageValue,
} from "../../../storage/LocalStorageHandler"
import { getMountNode } from "../../../containers/DarkModeContainers/CustomMountNode"
import Slider from "react-input-slider"
import {
  requestDeviceMotionIfNeeded,
  startShakeDetection,
} from "../../../common/DeviceMotion"
import { decodeAudioData, unlockAudio } from "../../../common/UnlockAudio"
import { mobileDetect } from "../../../common/MobileDetect"

const audioFilePath = require("../../../assets/audio/dancin.mp3").default

type TempoData = {
  tempo: number
  beats: number[]
}

const getAudioSourceParams = async (
  beats: number[],
  playbackTime: number,
  currBpm: number
) => {
  let i
  for (i = 0; i < beats.length - 1; ++i) {
    if (playbackTime >= beats[i] && playbackTime <= beats[i + 1]) {
      const timeGap = beats[i + 1] - beats[i]
      const wantedTimeGap = 60.0 / currBpm

      const desiredPlaybackRate = timeGap / wantedTimeGap

      if (desiredPlaybackRate <= 0.25) return 0.25
      if (desiredPlaybackRate >= 4) return 4
      return desiredPlaybackRate
    }
  }
  return 1.0
}

const AudioConductor = () => {
  const [uploading, setUploading] = useState(false)
  const [tempoData, setTempoData] = useState<TempoData | undefined>(undefined)
  const [playing, setPlaying] = useState(false)

  const playingRef = useRef(false)
  playingRef.current = playing

  const playerRef = useRef<HTMLAudioElement | undefined>(undefined)

  // sensitivity stuff
  const [displayPopover, setDisplayPopover] = useState(false)
  const [devicemotionSensitivity, setDevicemotionSensitivity] = useState(75)
  const [devicemotionEnabled, setDevicemotionEnabled] = useState(false)
  const disposeDeviceMotionContainer = useRef<(() => any) | undefined>(
    undefined
  )

  // tempo model stuff
  const [toggle, setToggle] = useState(false)
  const lastMS = useRef(0)
  const hasTap = useRef(false)
  const toggleRef = useRef(false)
  toggleRef.current = toggle

  useEffect(() => {
    const localSens = parseInt(getLocalStorageValue("devicemotionSensLevel"))
    setDevicemotionSensitivity(localSens)
  }, [])

  useEffect(() => {
    return () => {
      reset()
      disableDeviceMotion()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const reset = async () => {
    await stop()
    setUploading(false)
    setTempoData(undefined)
    playerRef.current = undefined
    clearWaveformContainer()
    resetTap()
  }

  const onUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event || !event.target || !event.target.files) return
    setUploading(true)

    const file: Blob = event.target.files[0]
    if (
      !file ||
      !(file instanceof Blob) ||
      !(
        file.type === `audio/wav` ||
        file.type === `audio/ogg` ||
        file.type === `audio/mp3` ||
        file.type === `audio/mpeg`
      )
    ) {
      message.config(getMessageConfig())
      message.error("No or unsupported file uploaded")
      setUploading(false)
      return
    }
    try {
      const player = new Audio()
      player.src = URL.createObjectURL(file)
      player.onended = () => {
        stop()
      }
      player.load()
      playerRef.current = player
      const buf = await file.arrayBuffer()
      await loadAudio(buf)
    } catch (err) {
      message.config(getMessageConfig())
      message.error(`Something went wrong.`)
    }
    setUploading(false)
  }

  const loadSampleAudio = async () => {
    setUploading(true)
    try {
      const player = new Audio()
      player.src = audioFilePath
      player.onended = () => {
        stop()
      }
      player.load()
      playerRef.current = player
      const response = await fetch(audioFilePath)
      const buf = await response.arrayBuffer()
      await loadAudio(buf)
    } catch (err) {
      console.error(err)
      message.config(getMessageConfig())
      message.error(`Something went wrong`)
    }
    setUploading(false)
  }

  const loadAudio = async (arrayBuf: ArrayBuffer) => {
    const buf = await decodeAudioData(arrayBuf)
    const sampleRate = buf.sampleRate

    // take the average of all channels
    const allChanData = buf.getChannelData(0)
    let i, j
    for (i = 1; i < buf.numberOfChannels; ++i) {
      const curChanData = buf.getChannelData(i)
      for (j = 0; j < allChanData.length; ++j) {
        allChanData[j] += curChanData[j]
      }
    }
    const audioData = allChanData.map((x) => x / buf.numberOfChannels)
    const audioArr = Array.from(audioData)
    const mt = new MusicTempo(audioData)

    const tData: TempoData = {
      tempo: mt.tempo,
      beats: mt.beats,
    }
    await calcAndDrawWaveform(audioArr, mt.beats, sampleRate)
    setTempoData(tData)
  }

  const play = async () => {
    // not actually start, need to tap
    setPlaying(true)
  }

  const stop = async () => {
    try {
      const player = playerRef.current
      if (player) {
        player.pause()
        player.currentTime = 0
      }
      setPlaying(false)
      updateShift(0)
    } catch (err) {
      message.config(getMessageConfig())
      message.error(`Something went wrong.`)
    }
    resetTap()
  }

  const animCycle = async () => {
    if (playingRef.current && playerRef.current) {
      const playbackTime = playerRef.current.currentTime
      try {
        await updateShift(playbackTime)
      } catch (err) {
        // message.config(getMessageConfig());
        // message.error(`Something went wrong.`);
      }
      requestAnimFrame(animCycle)
    }
  }

  const resetTap = async () => {
    lastMS.current = 0
    hasTap.current = false
  }

  // handleTap: either tap or shake
  const handleTap = async () => {
    if (!playerRef.current) {
      message.config(getMessageConfig())
      message.error(`Something went wrong.`)
      return
    }
    if (!tempoData || !tempoData.beats) {
      message.config(getMessageConfig())
      message.error(`Something went wrong.`)
      return
    }
    setToggle(!toggleRef.current)
    if (!hasTap.current) {
      hasTap.current = true
      lastMS.current = Date.now()
      unlockAudio()
      playerRef.current.play()
      animCycle()
    } else {
      const nowMS = Date.now()
      const gapMS = nowMS - lastMS.current
      let _currBpm = 60000.0 / gapMS
      lastMS.current = nowMS
      // limit to 300qpm
      const currBpm = _currBpm >= 300 ? 300 : _currBpm
      const playbackTime = playerRef.current.currentTime
      playerRef.current.playbackRate = await getAudioSourceParams(
        tempoData.beats,
        playbackTime,
        currBpm
      )
    }
  }

  const handleSenStateChange = async (newSen: any) => {
    setLocalStorageValue("devicemotionSensLevel", newSen.x.toString())
    setDevicemotionSensitivity(newSen.x)
    setDisplayPopover(true)
  }

  const onSenDragEnd = async () => {
    setDisplayPopover(false)
  }

  const enableDeviceMotion = async () => {
    try {
      await requestDeviceMotionIfNeeded()

      // 1 to 21
      const requiredSens = 20 - (devicemotionSensitivity / 100) * 20 + 1

      const disposeDeviceMotion = await startShakeDetection(
        handleTap,
        requiredSens
      )

      setDevicemotionEnabled(true)
      disposeDeviceMotionContainer.current = disposeDeviceMotion
    } catch (err) {
      message.config(getMessageConfig())
      message.error(`Something went wrong.`)
    }
  }

  const disableDeviceMotion = async () => {
    if (disposeDeviceMotionContainer.current) {
      disposeDeviceMotionContainer.current()
      disposeDeviceMotionContainer.current = undefined
      // console.log(`dispose`)
    }
    setDevicemotionEnabled(false)
  }

  return (
    <div className={styles.root}>
      <h5>
        Powered by{" "}
        <a
          href="http://www.eecs.qmul.ac.uk/~simond/pub/2007/jnmr07.pdf"
          rel="noopener noreferrer"
          target="_blank"
        >
          BeatRoot
        </a>
      </h5>

      {!tempoData ? (
        <div>
          <Button
            variant="success"
            size="lg"
            disabled={uploading}
            className={styles.btn}
            onClick={loadSampleAudio}
          >
            Use Sample Audio
          </Button>

          <Button
            variant="warning"
            size="lg"
            disabled={uploading}
            className={styles.btn}
          >
            <label style={{ margin: 0 }} htmlFor="multi">
              Upload Audio
            </label>
            <input
              style={{ display: "none" }}
              type="file"
              accept=".mp3,.wav,.ogg,.wave"
              id="multi"
              onChange={onUpload}
            />
          </Button>
        </div>
      ) : (
        <div>
          <Button
            variant="warning"
            size="lg"
            onClick={reset}
            className={styles.btn}
          >
            Reset
          </Button>
        </div>
      )}
      {uploading && (
        <div style={{ marginTop: 32 }}>
          <Loading />
        </div>
      )}
      <div
        id="waveformContainer"
        className={styles.waveform}
        style={{ marginTop: 24 }}
      ></div>

      {tempoData && (
        <div style={{ marginTop: 36 }}>
          {!playing ? (
            <Button variant="success" onClick={play} className={styles.btn}>
              Start
            </Button>
          ) : (
            <div>
              <Button variant="danger" onClick={stop} className={styles.btn}>
                Stop
              </Button>

              <div>
                {!toggle && (
                  <Button
                    className={styles.tapbtn}
                    variant="success"
                    size="lg"
                    onMouseDown={handleTap}
                  >
                    TAP
                  </Button>
                )}
                {toggle && (
                  <Button
                    className={styles.tapbtn}
                    variant="warning"
                    size="lg"
                    onMouseDown={handleTap}
                  >
                    TAP
                  </Button>
                )}
              </div>
              {mobileDetect.mobile() && !devicemotionEnabled && (
                <div style={{ marginTop: 36, marginBottom: 0 }}>
                  <h5>
                    On a device with a motion sensor? Try using it instead of
                    tapping!
                  </h5>
                  <Button onClick={enableDeviceMotion}>
                    Enable device motion
                  </Button>

                  <p style={{ marginTop: 12, marginBottom: 0 }}>
                    Device motion sensitivity
                  </p>
                  <p>
                    You can calibrate this sensitivity using{" "}
                    <Link to="/taptobpm">Tap-to-BPM</Link>
                  </p>
                  <Popover
                    getPopupContainer={getMountNode()}
                    placement="bottom"
                    visible={displayPopover}
                    content={
                      <div>
                        <h6 style={{ margin: 0 }}>
                          {devicemotionSensitivity.toFixed(0) + "%"}
                        </h6>
                      </div>
                    }
                  >
                    <Slider
                      axis="x"
                      xstep={1}
                      xmin={0}
                      xmax={100}
                      x={devicemotionSensitivity}
                      onChange={handleSenStateChange}
                      onDragEnd={onSenDragEnd}
                    />
                  </Popover>
                </div>
              )}
              {devicemotionEnabled && (
                <div>
                  <Button variant="danger" onClick={disableDeviceMotion}>
                    Disable device motion
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {mobileDetect.mobile() === "iPhone" && (
        <p>
          Due to iOS constraints, iOS users will hear sound gaps on each tap.
          This is a device limitation.
        </p>
      )}
    </div>
  )
}

export default AudioConductor
