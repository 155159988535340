import * as React from "react"
import { Route, Switch } from "react-router-dom"
import { ChromaticTuner } from "../../components/ChromaticTuner"
import { HomePage } from "../HomePage"
import { ToneGenerator } from "../../components/ToneGenerator"
import { TapToBPM } from "../../components/TapToBPM"
import { Metronome } from "../../components/Metronome"
import { Changelog } from "../../components/Changelog"
import { PageTurner } from "../../components/PageTurner"
import { PianoToMidi } from "../../components/PianoToMIDI"
import { Visualizer } from "../../components/Visualizer"
import Conductor from "../../components/Conductor/Conductor"

export class AppContent extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/" exact component={HomePage} />
        <Route path="/chromatictuner" exact component={ChromaticTuner} />
        <Route path="/tonegenerator" exact component={ToneGenerator} />
        <Route path="/metronome" exact component={Metronome} />
        <Route path="/taptobpm" exact component={TapToBPM} />
        <Route
          path="/pageturner"
          // exact
          component={PageTurner}
        />
        <Route path="/pianotomidi" exact component={PianoToMidi} />
        <Route path="/visualizer" exact component={Visualizer} />
        <Route path="/conductor" component={Conductor} />
        <Route path="/changelog" exact component={Changelog} />
        <Route component={HomePage} />
      </Switch>
    )
  }
}

export default AppContent
