import { message, Popover } from "antd"
import { Link } from "react-router-dom"
import {
  PianoRollSVGVisualizer,
  SoundFontPlayer,
  NoteSequence,
  blobToNoteSequence,
} from "@magenta/music"
import React, { useState, useEffect, useRef } from "react"
import { Button } from "react-bootstrap"
import { getMessageConfig } from "../../../containers/DarkModeContainers/CustomMessage"
import styles from "../Conductor.module.css"
import { requestAnimFrame } from "../../../common/RequestAnimFrame"
import {
  setLocalStorageValue,
  getLocalStorageValue,
} from "../../../storage/LocalStorageHandler"
import { getMountNode } from "../../../containers/DarkModeContainers/CustomMountNode"
import Slider from "react-input-slider"
import {
  requestDeviceMotionIfNeeded,
  startShakeDetection,
} from "../../../common/DeviceMotion"
import { unlockAudio } from "../../../common/UnlockAudio"
import { mobileDetect } from "../../../common/MobileDetect"

const midiFilePath = require("../../../assets/midi/prelude.mid").default

const MIDIConductor = () => {
  // player stuff
  const [noteSequence, setNoteSequence] = useState<Blob | undefined>(undefined)
  const [uploading, setUploading] = useState(false)
  const [playing, setPlaying] = useState(false)
  const [ready, setReady] = useState(false)
  const [player, setPlayer] = useState<SoundFontPlayer | undefined>(undefined)
  const playerRef = useRef<SoundFontPlayer | undefined>(undefined)
  playerRef.current = player
  const [svgVisualizer, setSvgVisualizer] = useState<
    PianoRollSVGVisualizer | undefined
  >(undefined)
  const svgVisualizerRef = useRef<PianoRollSVGVisualizer | undefined>(undefined)
  svgVisualizerRef.current = svgVisualizer

  // sensitivity stuff
  const [displayPopover, setDisplayPopover] = useState(false)
  const [devicemotionSensitivity, setDevicemotionSensitivity] = useState(75)
  const [devicemotionEnabled, setDevicemotionEnabled] = useState(false)
  const disposeDeviceMotionContainer = useRef<(() => any) | undefined>(
    undefined
  )

  // tempo model stuff
  const [toggle, setToggle] = useState(false)
  const lastMS = useRef(0)
  const hasTap = useRef(false)
  const toggleRef = useRef(false)
  toggleRef.current = toggle

  useEffect(() => {
    const localSens = parseInt(getLocalStorageValue("devicemotionSensLevel"))
    setDevicemotionSensitivity(localSens)
  }, [])

  useEffect(() => {
    return () => {
      if (player && player.isPlaying()) {
        player.stop()
      }
      disableDeviceMotion()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const processAndSetNoteSequence = async (midiFile: Blob) => {
    try {
      const ns = await blobToNoteSequence(midiFile)
      setNoteSequence(ns)
      initVisualizerAndPlayer(ns)
    } catch (err) {
      message.config(getMessageConfig())
      message.error("Error: MIDI file corrupted. Please try again.")
    }
  }

  const initVisualizerAndPlayer = async (ns: NoteSequence) => {
    const svg = document.getElementsByTagName("svg")[0] as SVGSVGElement
    if (!svg) {
      requestAnimFrame(() => initVisualizerAndPlayer(ns))
      return
    }
    const svgVisualizer = new PianoRollSVGVisualizer(ns, svg)
    const callbackObject = {
      run: (note: NoteSequence.Note) => {
        svgVisualizer.redraw(note, true)
      },
      stop: () => {},
    }
    const player = new SoundFontPlayer(
      "https://storage.googleapis.com/magentadata/js/soundfonts/sgm_plus",
      undefined,
      undefined,
      undefined,
      callbackObject
    )
    await player.loadSamples(ns)

    setSvgVisualizer(svgVisualizer)
    setPlayer(player)
    setReady(true)
  }

  const onUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event || !event.target || !event.target.files) return
    setUploading(true)

    const file: Blob = event.target.files[0]
    if (
      !file ||
      !(file instanceof Blob) ||
      !(file.type === "audio/midi" || file.type === "audio/mid")
    ) {
      message.config(getMessageConfig())
      message.error("No or unsupported file uploaded")
      setUploading(false)
      return
    }
    setUploading(false)
    processAndSetNoteSequence(file)
  }

  const loadSampleMIDI = async () => {
    setUploading(true)
    try {
      const response = await fetch(midiFilePath)
      const data = await response.blob()
      const metadata = {
        type: "audio/midi",
      }
      const file = new File([data], "prelude.mid", metadata)
      processAndSetNoteSequence(file)
    } catch (err) {
      message.config(getMessageConfig())
      message.error(`Something went wrong.`)
    }
    setUploading(false)
  }

  const reset = async () => {
    if (svgVisualizer) svgVisualizer.clearActiveNotes()
    if (player && player.isPlaying()) player.stop()

    setNoteSequence(undefined)
    setUploading(false)
    setPlaying(false)
    setSvgVisualizer(undefined)
    setPlayer(undefined)
    setReady(false)
    resetTap()
  }

  const play = async () => {
    // not actually start, need to tap
    setPlaying(true)
  }

  const stop = () => {
    setPlaying(false)
    if (player && player.isPlaying()) player.stop()
    resetTap()
    disableDeviceMotion()
  }

  const resetTap = async () => {
    lastMS.current = 0
    hasTap.current = false
  }

  // handleTap: either tap or shake
  const handleTap = async () => {
    setToggle(!toggleRef.current)
    if (!hasTap.current) {
      hasTap.current = true
      lastMS.current = Date.now()
      const p = playerRef.current
      const s = svgVisualizerRef.current
      if (p && s) {
        unlockAudio()
        p.start(s.noteSequence)
      }
    } else {
      const nowMS = Date.now()
      const gapMS = nowMS - lastMS.current
      const currBpm = 60000.0 / gapMS
      lastMS.current = nowMS
      // limit to 300qpm
      const p = playerRef.current
      if (p) {
        if (!p.isPlaying()) {
          const s = svgVisualizerRef.current
          if (s) p.start(s.noteSequence)
        }
        p.setTempo(currBpm >= 300 ? 300 : currBpm)
      }
    }
  }

  const handleSenStateChange = async (newSen: any) => {
    setLocalStorageValue("devicemotionSensLevel", newSen.x.toString())
    setDevicemotionSensitivity(newSen.x)
    setDisplayPopover(true)
  }

  const onSenDragEnd = async () => {
    setDisplayPopover(false)
  }

  const enableDeviceMotion = async () => {
    try {
      await requestDeviceMotionIfNeeded()

      // 1 to 21
      const requiredSens = 20 - (devicemotionSensitivity / 100) * 20 + 1

      const disposeDeviceMotion = await startShakeDetection(
        handleTap,
        requiredSens
      )

      setDevicemotionEnabled(true)
      disposeDeviceMotionContainer.current = disposeDeviceMotion
    } catch (err) {
      message.config(getMessageConfig())
      message.error(`Something went wrong.`)
    }
  }

  const disableDeviceMotion = async () => {
    if (disposeDeviceMotionContainer.current) {
      disposeDeviceMotionContainer.current()
      disposeDeviceMotionContainer.current = undefined
      // console.log(`dispose`)
    }
    setDevicemotionEnabled(false)
  }

  return (
    <div className={styles.root}>
      <h5>
        Soundfont by{" "}
        <a
          href="https://www.polyphone-soundfonts.com/en/files/27-instrument-sets/256-sgm-v2-01"
          rel="noopener noreferrer"
          target="_blank"
        >
          SGM
        </a>{" "}
        | Visualiser by{" "}
        <a
          href="https://magenta.tensorflow.org/"
          rel="noopener noreferrer"
          target="_blank"
        >
          Magenta.js
        </a>
      </h5>

      {!noteSequence ? (
        <div>
          <Button
            variant="success"
            size="lg"
            disabled={uploading}
            className={styles.btn}
            onClick={loadSampleMIDI}
          >
            Use Sample MIDI
          </Button>

          <Button
            variant="warning"
            size="lg"
            disabled={uploading}
            className={styles.btn}
          >
            <label style={{ margin: 0 }} htmlFor="multi">
              Upload MIDI
            </label>
            <input
              style={{ display: "none" }}
              type="file"
              accept=".mid,.midi"
              id="multi"
              onChange={onUpload}
            />
          </Button>
        </div>
      ) : (
        <div>
          <Button
            variant="warning"
            size="lg"
            onClick={reset}
            className={styles.btn}
          >
            Reset
          </Button>
        </div>
      )}
      {noteSequence && (
        <div>
          <div className={styles.row}>
            <svg id="svg" className={styles.svg}></svg>
          </div>
          {!playing ? (
            <Button
              variant="success"
              onClick={play}
              className={styles.btn}
              disabled={!ready}
            >
              {ready ? "Start" : "Loading..."}
            </Button>
          ) : (
            <div>
              <Button variant="danger" onClick={stop} className={styles.btn}>
                Stop
              </Button>

              <div>
                {!toggle && (
                  <Button
                    className={styles.tapbtn}
                    variant="success"
                    size="lg"
                    onMouseDown={handleTap}
                  >
                    TAP
                  </Button>
                )}
                {toggle && (
                  <Button
                    className={styles.tapbtn}
                    variant="warning"
                    size="lg"
                    onMouseDown={handleTap}
                  >
                    TAP
                  </Button>
                )}
              </div>
              {mobileDetect.mobile() && !devicemotionEnabled && (
                <div style={{ marginTop: 36, marginBottom: 0 }}>
                  <h5>
                    On a device with a motion sensor? Try using it instead of
                    tapping!
                  </h5>
                  <Button onClick={enableDeviceMotion}>
                    Enable device motion
                  </Button>

                  <p style={{ marginTop: 12, marginBottom: 0 }}>
                    Device motion sensitivity
                  </p>
                  <p>
                    You can calibrate this sensitivity using{" "}
                    <Link to="/taptobpm">Tap-to-BPM</Link>
                  </p>
                  <Popover
                    getPopupContainer={getMountNode()}
                    placement="bottom"
                    visible={displayPopover}
                    content={
                      <div>
                        <h6 style={{ margin: 0 }}>
                          {devicemotionSensitivity.toFixed(0) + "%"}
                        </h6>
                      </div>
                    }
                  >
                    <Slider
                      axis="x"
                      xstep={1}
                      xmin={0}
                      xmax={100}
                      x={devicemotionSensitivity}
                      onChange={handleSenStateChange}
                      onDragEnd={onSenDragEnd}
                    />
                  </Popover>
                </div>
              )}
              {devicemotionEnabled && (
                <div>
                  <Button variant="danger" onClick={disableDeviceMotion}>
                    Disable device motion
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default MIDIConductor
