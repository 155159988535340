import * as React from "react"
import styles from "./BouncyBall.module.css"

interface IProps {
  position: number
}

const style: { [s: string]: string } = {
  // display: 'block',
  position: "absolute",
  width: "50px",
  height: "50px",
  borderRadius: "50%",
  backgroundColor: "#f39c12",
  left: "50%",
  marginLeft: "-25px",
  marginTop: "275px",
}

const Ball = ({ position }: any) => {
  return (
    <div
      style={{
        ...style,
        bottom: position,
      }}
    />
  )
}

class BouncyBall extends React.Component<IProps> {
  render() {
    return (
      <div className={styles.root}>
        <Ball position={this.props.position} />
      </div>
    )
  }
}

export default BouncyBall
