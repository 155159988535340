import * as React from "react"
import { connect } from "react-redux"
import { ModalDialog } from "react-bootstrap"
import "./CustomModalDialog.css"

interface IProps {
  darkModeReducer: boolean
  dispatch: any
}

const defaultProps = {
  darkModeReducer: false,
  dispatch: null,
}

export class CustomModalDialog extends React.Component<IProps> {
  public static defaultProps = defaultProps

  getRootClass = () => {
    const { darkModeReducer } = this.props
    if (darkModeReducer) {
      return "darkmodal"
    }
    return "lightmodal"
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { darkModeReducer, dispatch, ...other } = this.props
    return (
      <div className={this.getRootClass()}>
        <ModalDialog {...other} />
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  ...state,
})

export default connect(mapStateToProps)(CustomModalDialog)
