import * as React from "react"

import styles from "./PianoToMidi.module.css"
import { Loading } from "../Loading"
import { Error } from "../Error"
import { Button } from "react-bootstrap"
import { message } from "antd"
import { getMessageConfig } from "../../containers/DarkModeContainers/CustomMessage"
import {
  OnsetsAndFrames,
  NoteSequence,
  sequenceProtoToMidi,
} from "@magenta/music"
import { saveAs } from "file-saver"
import { Visualizer } from "../Visualizer"

interface IProps {}

interface IState {
  loading: boolean
  model: any
  uploading: boolean
  transcribing: boolean
  inputFile: any
  outputFile: any
  success: boolean
  error: boolean
  visualizer: boolean
  noteSequence: any
}

export class PianoToMidi extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props)
    this.state = {
      loading: true,
      model: null,
      uploading: false,
      transcribing: false,
      inputFile: null,
      outputFile: null,
      success: false,
      error: false,
      visualizer: false,
      noteSequence: null,
    }
  }

  componentDidMount = () => {
    const model = new OnsetsAndFrames(
      "https://storage.googleapis.com/magentadata/js/checkpoints/transcription/onsets_frames_uni"
    )

    model
      .initialize()
      .then(() => {
        this.setState({
          ...this.state,
          model: model,
          loading: false,
        })
      })
      .catch(() => {
        this.setState({
          error: true,
        })
      })
    // console.log(model);
  }

  onUpload = (event: any) => {
    this.setState({
      uploading: true,
    })

    const file: Blob = event.target.files[0]
    if (
      !file ||
      !(file instanceof Blob) ||
      !(
        file.type === "audio/wav" ||
        file.type === "audio/mp3" ||
        file.type === "audio/mpeg"
      )
    ) {
      message.config(getMessageConfig())
      message.error("No or unsupported file uploaded")
      return
    }
    // console.log(file);

    this.setState({
      uploading: false,
      inputFile: file,
      transcribing: true,
    })

    this.transcribeFromFile(file as Blob)
  }

  transcribeFromFile = (blob: Blob) => {
    const { model } = this.state
    model
      .transcribeFromAudioFile(blob)
      .then((ns: NoteSequence) => {
        const outputFile = new File([sequenceProtoToMidi(ns)], "output.mid")

        // console.log(outputFile);

        this.setState({
          transcribing: false,
          outputFile: outputFile,
          success: true,
          noteSequence: ns,
        })
      })
      .catch((e: any) => {
        message.config(getMessageConfig())
        message.error("An error occured, couldn't transcribe the audio.")
        console.log(e)

        this.reset()
      })
  }

  reset = () => {
    this.setState({
      uploading: false,
      transcribing: false,
      inputFile: null,
      outputFile: null,
      success: false,
      error: false,
      visualizer: false,
    })
  }

  download = () => {
    const { outputFile } = this.state
    saveAs(outputFile)
  }

  toggleVisualizer = () => {
    this.setState({
      visualizer: !this.state.visualizer,
    })
  }

  render() {
    const {
      loading,
      uploading,
      transcribing,
      error,
      success,
      visualizer,
      outputFile,
      noteSequence,
    } = this.state
    if (loading) {
      return <Loading loadingMessage="Please wait... loading ML models" />
    }
    if (error) {
      return <Error />
    }
    return (
      <div className={styles.root}>
        {/* <img className={styles.mainIcon} src={page} alt=""/> */}
        <h1>Piano-to-MIDI</h1>
        <h4>Machine learning conversion of piano audio to MIDI</h4>
        <h5>
          <a
            href="https://soundcloud.com/lhleemusic/sets/tuneapp-piano-to-midi-demos"
            rel="noopener noreferrer"
            target="_blank"
          >
            Demos
          </a>{" "}
          | Powered by{" "}
          <a
            href="https://magenta.tensorflow.org/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Magenta.js
          </a>{" "}
          and{" "}
          <a
            href="https://www.tensorflow.org/js/"
            rel="noopener noreferrer"
            target="_blank"
          >
            TensorFlow.js
          </a>
        </h5>

        {!success && (
          <div>
            <Button
              variant="warning"
              size="lg"
              disabled={uploading || transcribing}
            >
              <label htmlFor="multi" style={{ margin: 0 }}>
                Upload Audio File
              </label>
              <input
                style={{ display: "none" }}
                type="file"
                accept="audio/mpeg,audio/wav"
                id="multi"
                onChange={this.onUpload}
              />
            </Button>

            {transcribing && (
              <div>
                <h5 style={{ marginTop: 20, marginBottom: 0 }}>
                  Please be patient - transcribing...
                </h5>
                <h5 style={{ marginTop: 0 }}>
                  Your device may become unresponsive.
                </h5>
              </div>
            )}
          </div>
        )}

        {success && (
          <div style={{ marginTop: 30 }}>
            <h3 style={{ color: "green", fontWeight: 800 }}>
              Transcription successful
            </h3>
            <div style={{ marginTop: 20 }}>
              <Button
                className={styles.btn}
                variant="warning"
                size="lg"
                onClick={this.toggleVisualizer}
              >
                {visualizer ? "Close Visualizer" : "Open Visualizer"}
              </Button>
              <Button
                className={styles.btn}
                variant="success"
                size="lg"
                onClick={this.download}
              >
                Download output MIDI
              </Button>
              <Button
                className={styles.btn}
                variant="danger"
                size="lg"
                onClick={this.reset}
              >
                Start over
              </Button>
            </div>
          </div>
        )}
        {visualizer && (
          <Visualizer
            propsMidiFile={outputFile}
            propsNoteSequence={noteSequence}
          />
        )}
      </div>
    )
  }
}

export default PianoToMidi
