import * as React from "react"
import styles from "./Changelog.module.css"

export const changelog: Record<string, { date: string; features: string[] }> = {
  "1.5.9": {
    date: "02/05/2021",
    features: ["Cleanup", "Update dependencies"],
  },
  "1.5.8": {
    date: "13/12/2020",
    features: ["Fix AudioContext tuner bug with iOS and desktop Safari"],
  },
  "1.5.7": {
    date: "13/12/2020",
    features: [
      "Fix issue with chromatic tuner not starting up properly (Thanks Olivia Gallup)",
      "Update dependencies",
    ],
  },
  "1.5.6": {
    date: "24/11/2020",
    features: ["Cleanup"],
  },
  "1.5.5": {
    date: "23/11/2020",
    features: [
      "Fix TypeScript AudioContext compatibility bug with Safari & iOS",
      "Fix iPhone specific warnings",
    ],
  },
  "1.5.4": {
    date: "23/11/2020",
    features: [
      "Update dependencies",
      "Add warning for Audio Conductor for iPhone users",
    ],
  },
  "1.5.3": {
    date: "22/11/2020",
    features: [
      "Add warning for iPhone users",
      "Draft MusicXML Conductor (not working on iPhone)",
    ],
  },
  "1.5.2": {
    date: "22/11/2020",
    features: ["Fix Audio Conductor sample rate mismatch issue"],
  },
  "1.5.1": {
    date: "21/11/2020",
    features: [
      "Small bugfixes",
      "Remove pause/resume in MIDI Conductor as it was misleading",
      "Add Audio Conductor BETA",
    ],
  },
  "1.5.0": {
    date: "21/11/2020",
    features: [
      "Update dependencies",
      "Add motion detection to Tap-To-BPM",
      "Add Conductor BETA",
    ],
  },
  "1.4.4": {
    date: "5/6/2020",
    features: ["Update dependencies", "Update links"],
  },
  "1.4.3": {
    date: "22/4/2020",
    features: ["Consider adding ads"],
  },
  "1.4.2": {
    date: "22/4/2020",
    features: ["Optimise PageTurner"],
  },
  "1.4.1": {
    date: "21/4/2020",
    features: ["Renew copyright year", "Update and cleanup packages"],
  },
  "1.4.0": {
    date: "16/12/2019",
    features: ["Add MIDI Visualizer as a feature and to Piano-To-MIDI"],
  },
  "1.3.1": {
    date: "15/12/2019",
    features: ["Add link to demos."],
  },
  "1.3.0": {
    date: "15/12/2019",
    features: ["Add first version of Piano-To-MIDI."],
  },
  "1.2.3": {
    date: "03/11/2019",
    features: [
      "Remove BETA branding - PageTurner ready to go.",
      "Move Page Turner sequence to second.",
    ],
  },
  "1.2.2": {
    date: "27/10/2019",
    features: [
      "Improve styling of dark mode pop-up components.",
      "Add Popover component to show percentages in Slider components.",
      "Suppress iPhone errors for locked audio",
    ],
  },
  "1.2.1": {
    date: "26/10/2019",
    features: [
      "Page Turner: improve connectivity consistency and component design",
      "Enable 2-page view for PDF",
    ],
  },
  "1.2.0": {
    date: "25/10/2019",
    features: [
      "Page Turner BETA released",
      "Using pop-ups for warnings instead of modals to reduce annoyance",
    ],
  },
  "1.1.2": {
    date: "20/10/2019",
    features: ["Add bouncing animation to metronome; add stylistic changes"],
  },
  "1.1.1": {
    date: "20/10/2019",
    features: ["Persist microphone level upon reload/revisit."],
  },
  "1.1.0": {
    date: "20/10/2019",
    features: [
      "DARK MODE Available",
      "Persistent A4 and Dark Mode for user convenience",
      "Cosmetic changes",
    ],
  },
  "1.0.0": {
    date: "13/10/2019",
    features: [
      "*Final release*",
      "Cosmetic changes: logo, removal of BETA branding",
      "Add more warnings for iPhone users",
    ],
  },
  "0.0.3 BETA": {
    date: "11/10/2019",
    features: [
      "Chromatic tuner: fix bug in which wrong note is deduced causing uncontrolled swirling in gauge",
    ],
  },
  "0.0.2 BETA": {
    date: "10/10/2019",
    features: [
      "Chromatic tuner: make default microphone level lower, brand new gauge, smoother needle action",
      "Tone generator: improve behavior so that note doesn't stop when keyboard played",
      "Links now open in new tab",
      "Metronome: make it more intuitive to add a new beat group by using onBlur",
    ],
  },
  "0.0.1 BETA": {
    date: "09/10/2019",
    features: [
      "Chromatic tuner: add microphone level control",
      "Tone generator: add piano roll",
      "Front end: clean up and new graphics",
      "Bug fix: Metronome groups of 1",
    ],
  },
  "0.0.0 BETA": {
    date: "09/10/2019",
    features: ["Initial release"],
  },
}

export class Changelog extends React.Component {
  render() {
    return (
      <div className={styles.root}>
        <h1>Changelog</h1>
        <a href="mailto:l.h.lee2617@gmail.com">Support</a> |{" "}
        <a href="mailto:l.h.lee2617@gmail.com">Bug report</a> |{" "}
        <a href="mailto:l.h.lee2617@gmail.com">Feature request</a>
        {Object.entries(changelog).map(([verKey, obj], index) => (
          <div key={index}>
            {index === 0 ? (
              <h2>
                Version {verKey} - {obj && obj.date}
              </h2>
            ) : (
              <h3>
                Version {verKey} - {obj && obj.date}
              </h3>
            )}
            <ul>
              {obj &&
                obj.features.map((feat, index) => {
                  return <li key={index}>{feat}</li>
                })}
            </ul>
          </div>
        ))}
      </div>
    )
  }
}

export default Changelog
