/* eslint-disable no-unused-vars */
import { message } from "antd"
import { getMessageConfig } from "../../../containers/DarkModeContainers/CustomMessage"
import { sleep } from "../../../utils/sleep"

const pitchDetector = require("pitch-detector")

export class TuningInfo {
  constructor(
    public frequency: number,
    public octave: number,
    public note: string,
    public detune: number
  ) {}
}

const notes = ["C", "C#", "D", "D#", "E", "F", "F#", "G", "G#", "A", "A#", "B"]

const frequencyFromNoteNumber = (note: number, octave: number, A: number) => {
  // if (note > 2) octave--; // for C and beyond;
  return A * Math.pow(2, (note - 9) / 12) * Math.pow(2, octave - 4)
}

const centsOffFromPitch = (
  frequency: number,
  note: number,
  octave: number,
  A: number
) => {
  const detune =
    (1200 * Math.log(frequency / frequencyFromNoteNumber(note, octave, A))) /
    Math.log(2)
  // console.log(frequency + " " + frequencyFromNoteNumber(note, octave, A) + " " + detune);
  return detune
}

export class AudioProcessor {
  FFTSIZE: any
  stream: any
  audioContext: AudioContext
  analyser: AnalyserNode
  gainNode: any
  microphone: any
  frequencyBufferLength: number
  frequencyBuffer: Float32Array
  sendingAudioData: any
  A: any
  tuningInfo: any
  playLouder: any
  volume: any
  micAccess: any
  initComplete: any
  err: boolean = false
  errMsg: string = ""
  micLevel: number
  errCode: number = 0 // 69: unsupported browser

  constructor(A: number, micLevel: number) {
    // @ts-ignore
    this.audioContext = new (window.AudioContext || window.webkitAudioContext)() // can't use the global one here :(

    this.micLevel = micLevel

    this.FFTSIZE = 1024
    this.stream = null
    this.analyser = this.audioContext.createAnalyser()
    this.microphone = null

    this.analyser.smoothingTimeConstant = 0

    this.frequencyBufferLength = this.FFTSIZE
    this.frequencyBuffer = new Float32Array(this.frequencyBufferLength)

    this.tuningInfo = new TuningInfo(0, 0, "", 0)

    this.playLouder = true

    this.micAccess = false

    this.initComplete = false

    this.A = A
    // Bind as we would have done for anything in the constructor so we can use
    // them without confusing what 'this' means. Yay window scoped.
    this.dispatchAudioData = this.dispatchAudioData.bind(this)
  }

  errorCB() {
    // console.log("Something went wrong...");
    message.config(getMessageConfig())
    message.error("Please allow microphone access.")
  }

  reportError(err: string, errCode: number) {
    this.err = true
    this.errMsg = err
    this.errCode = 69
  }

  requestUserMedia() {
    const constraints = {
      audio: {
        autoGainControl: false,
        echoCancellation: false,
        noiseSuppression: false,
      },
      video: false,
    }
    if (!navigator.mediaDevices) {
      this.reportError("Unsupported browser.", 69)
      return
    }
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        this.sendingAudioData = true
        this.stream = stream
        this.microphone = this.audioContext.createMediaStreamSource(stream)
        this.microphone.connect(this.analyser)
        this.micAccess = true
        this.initComplete = true
        requestAnimationFrame(
          this.dispatchAudioData.bind(this, this.tuningInfo)
        )
      })
      .catch(() => {
        this.initComplete = true
        this.errorCB()
      })
  }

  init() {
    this.requestUserMedia()
  }

  teardown() {
    this.sendingAudioData = false

    if (this.stream) {
      // Chrome 47+
      this.stream.getAudioTracks().forEach((track: any) => {
        if ("stop" in track) {
          track.stop()
        }
      })

      // Chrome 46-
      if ("stop" in this.stream) {
        this.stream.stop()
      }
    }

    this.stream = null
  }

  getPitch() {
    let sampleRate = this.audioContext.sampleRate
    let rms = 0

    // miclevel is between 0 and 100
    if (!this.micLevel) return 0
    let rmsMin = (100 - this.micLevel) / 5000.0
    // console.log(rmsMin);

    // Fill up the data.
    getFloatTimeDomainData(this.analyser, this.frequencyBuffer)
    // this.analyser.getFloatTimeDomainData(this.frequencyBuffer);

    // Figure out the root-mean-square, or rms, of the audio. Basically
    // this seems to be the amount of signal in the buffer.
    for (let d = 0; d < this.frequencyBuffer.length; d++) {
      rms += this.frequencyBuffer[d] * this.frequencyBuffer[d]
    }

    rms = Math.sqrt(rms / this.frequencyBuffer.length)

    this.volume = ((rms / rmsMin) * 50).toFixed(0)

    // If there's little signal in the buffer quit out.
    if (rms < rmsMin) {
      this.playLouder = true
      return 0
    }

    this.playLouder = false

    const hz = pitchDetector.Pitcher.pitch(this.frequencyBuffer, sampleRate)

    if (hz > 0) {
      return hz
    }
    return 0
  }

  async dispatchAudioData() {
    // Always set up the next pass here, because we could
    // early return from this pass if there's not a lot
    // of exciting data to deal with.
    if (this.sendingAudioData) {
      // sleep for 50ms for smoother data
      await sleep(50)
      requestAnimationFrame(this.dispatchAudioData.bind(this, this.tuningInfo))
    }

    const pitch = this.getPitch()

    if (pitch > 0) {
      // Convert the most active frequency to linear, based on A440.

      const h = Math.round(
        12 * Math.log2(pitch / (this.A * Math.pow(2, -4.75)))
      )

      const octave = Math.floor(h / 12)

      const noteNumber = h % 12

      const note = notes[noteNumber]
      // const dominantFrequency = Math.log2(pitch / this.A);

      // // Figure out how many semitones that equates to.
      // const semitonesFromA4 = Math.floor(12 * dominantFrequency);

      // // The octave is A440 for 4, so start there, then adjust by the
      // // number of semitones. Since we're at A, we need only 3 more to
      // // push us up to octave 5, and 9 to drop us to 3. So there's the magic
      // // 9 in that line below accounted for.
      // const octave = Math.floor(4 + ((9 + semitonesFromA4) / 12));

      // console.log(octave);
      // // The note is 0 for A, all the way to 11 for G#.
      // const noteNumber = (12 + (Math.floor(semitonesFromA4) % 12)) % 12;
      // const note = notes[noteNumber];

      const detune = centsOffFromPitch(pitch, noteNumber, octave, this.A)

      // Now tell anyone who's interested.

      // round
      const RoundedPitch = pitch.toFixed(2)
      const RoundedDetune = Math.round(detune)

      this.tuningInfo = new TuningInfo(
        RoundedPitch,
        octave,
        note,
        RoundedDetune
      )
    }
  }
}

const getFloatTimeDomainData = (
  analyser: AnalyserNode,
  freqBuf: Float32Array
) => {
  if (analyser.getFloatTimeDomainData) {
    analyser.getFloatTimeDomainData(freqBuf)
  } else {
    let uint8 = new Uint8Array(analyser.fftSize)
    analyser.getByteTimeDomainData(uint8)
    for (let i = 0; i < freqBuf.length; ++i) {
      freqBuf[i] = (uint8[i] - 128) * 0.0078125
    }
  }
}
