import * as React from "react"
import { VideoClass } from "./Classes"
import ReactDOM from "react-dom"
import styles from "./GestureControl.module.css"
import Slider from "react-input-slider"
import {
  setLocalStorageValue,
  getLocalStorageValue,
} from "../../../../storage/LocalStorageHandler"
import { message, Popover } from "antd"
import { Button } from "react-bootstrap"
import { getMessageConfig } from "../../../../containers/DarkModeContainers/CustomMessage"
import { getMountNode } from "../../../../containers/DarkModeContainers/CustomMountNode"
import { sleep } from "../../../../utils/sleep"
import { mobileDetect } from "../../../../common/MobileDetect"

interface IProps {
  rightCallBack: any
  leftCallBack: any
}

interface IState {
  videoReady: boolean
  motionDetected: boolean
  motionLeft: boolean
  motionRight: boolean
  threshold: number
  motionThreshold: number
  sensitivity: number
  start: boolean
  btnLoading: boolean
  displayPopover: boolean
}

let videoClass: VideoClass = new VideoClass()

const dimConst: number = 240
const sampleSize: number = 20
const blockNo: number = dimConst / sampleSize // how many blocks / side
let prevFrame: Array<number> = Array(blockNo * blockNo).fill(0)
let pollCheckCount: number = 0

export class GestureControl extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props)
    this.state = {
      videoReady: false,
      motionDetected: false,
      motionLeft: false,
      motionRight: false,
      threshold: 25, // color diff - range from 10 - 40
      motionThreshold: 25, // no of blocks - range from 10 - 40
      sensitivity: 25,
      start: false,
      btnLoading: false,
      displayPopover: false,
    }
  }

  componentDidMount = () => {
    videoClass = new VideoClass()
    const localSens = parseInt(getLocalStorageValue("sensLevel"))
    const newThreshold: number = 50 - localSens // must be opposite!
    this.setState({
      sensitivity: localSens,
      motionThreshold: newThreshold,
      threshold: newThreshold,
    })
  }

  componentWillUnmount = () => {}

  start = () => {
    const md = mobileDetect
    if (md.mobile() === "iPhone" && md.userAgent() !== "Safari") {
      message.config(getMessageConfig())
      message.error("Unsupported browser. Please use Safari on your iPhone.")
      return
    }

    this.setState({
      btnLoading: true,
    })

    const node = ReactDOM.findDOMNode(this)
    // @ts-ignore
    const video = node.querySelector(".videoCls")

    videoClass.startInit(video)

    this.pollCheck()
  }

  stop = () => {
    this.setState({
      start: false,
    })
    const node = ReactDOM.findDOMNode(this)
    // @ts-ignore
    const video = node.querySelector(".videoCls")

    videoClass.teardown(video.srcObject)
    // console.log('stopped');
  }

  pollCheck = () => {
    if (pollCheckCount === 500) {
      // max poll
      this.setState({
        start: false,
        btnLoading: false,
      })
    }
    if (videoClass.ready === true) {
      this.setState({
        videoReady: true,
        start: true,
        btnLoading: false,
      })
      const node = ReactDOM.findDOMNode(this)
      // @ts-ignore
      const video = node.querySelector(".videoCls")

      let videoPromise = video.play()

      if (videoPromise !== undefined) {
        videoPromise
          .then(() => {
            // message.info('ok');

            // set up canvas here
            // @ts-ignore
            const canvas: any = node.querySelector(".canvasCls")
            let ctx = canvas.getContext("2d")

            // ctx.drawImage(video, 0, 0, 320, 240);
            this.pollDraw(ctx, video, video.width, video.height)
          })
          .catch((e: any) => {
            console.error(e)
            message.config(getMessageConfig())
            message.error("Something went wrong...")
          })
      }
    } else {
      pollCheckCount++
      requestAnimationFrame(this.pollCheck)
    }
  }

  pollDraw = async (ctx: any, video: any, w: number, h: number) => {
    const { threshold, motionThreshold } = this.state
    ctx.drawImage(video, 0, 0, w, h)
    const data = ctx.getImageData(0, 0, w, h).data

    let motionNumber: number = 0
    let leftMotion: number = 0
    let rightMotion: number = 0

    for (let y = 0; y < blockNo; y++) {
      for (let x = 0; x < blockNo; x++) {
        const pos: number = (x * sampleSize + y * w * sampleSize) * 4

        const r = data[pos]
        // const g = data[pos + 1];
        // const b = data[pos + 2];

        if (prevFrame.length > pos) {
          if (Math.abs(prevFrame[pos] - r) > threshold) {
            if (x < blockNo / 2) {
              leftMotion++
            } else {
              rightMotion++
            }
            motionNumber++
          }
        }

        if (motionNumber > motionThreshold && !this.state.motionDetected) {
          const { leftCallBack, rightCallBack } = this.props
          if (this.state.start && leftMotion > rightMotion) {
            this.setState({
              motionLeft: true,
              motionDetected: true,
            })
            // console.log('t');
            leftCallBack()
          } else if (this.state.start) {
            this.setState({
              motionRight: true,
              motionDetected: true,
            })
            rightCallBack()
          }

          // sleep for 500 ms before requesting
          if (this.state.start) {
            await sleep(500).then(() => {
              requestAnimationFrame(() => this.pollDraw(ctx, video, w, h))
            })
          }

          return
        }
        prevFrame[pos] = r
      }
    }

    if (
      this.state.motionDetected ||
      this.state.motionLeft ||
      this.state.motionRight
    ) {
      this.setState({
        motionDetected: false,
        motionLeft: false,
        motionRight: false,
      })
    }
    if (this.state.start) {
      await sleep(25).then(() => {
        requestAnimationFrame(() => this.pollDraw(ctx, video, w, h))
      })
    }
  }

  handleSenStateChange = (newSen: any) => {
    setLocalStorageValue("sensLevel", newSen.x.toString())
    const newThreshold: number = 50 - newSen.x // must be opposite!
    this.setState({
      sensitivity: newSen.x,
      motionThreshold: newThreshold,
      threshold: newThreshold,
      displayPopover: true,
    })
  }

  onSenDragEnd = () => {
    this.setState({
      ...this.state,
      displayPopover: false,
    })
  }

  render() {
    const {
      videoReady,
      motionLeft,
      motionRight,
      sensitivity,
      start,
      btnLoading,
      displayPopover,
    } = this.state
    return (
      <div>
        {!start && (
          <Button
            disabled={btnLoading}
            className={styles.startBtn}
            variant="success"
            onClick={this.start}
          >
            Enable Motion Detection
          </Button>
        )}
        {start && (
          <Button
            className={styles.startBtn}
            variant="danger"
            onClick={this.stop}
          >
            Stop Motion Detection
          </Button>
        )}
        {btnLoading && !videoReady && <p>Loading...</p>}
        {videoReady && start && <p>Ready</p> && (
          <div className={styles.volume}>
            <h4>Hover your hand over your camera to turn pages!</h4>
            <p>Adjust sensitivity as required.</p>

            <h1 className={styles.modeFormTitle}>Sensitivity</h1>
            <Popover
              getPopupContainer={getMountNode()}
              placement="bottom"
              visible={displayPopover}
              content={
                <div>
                  <h6 style={{ margin: 0 }}>
                    {(((sensitivity - 10) * 10) / 3).toFixed(0) + "%"}
                  </h6>
                </div>
              }
            >
              <Slider
                axis="x"
                xstep={1}
                xmin={10}
                xmax={40}
                x={sensitivity}
                onChange={this.handleSenStateChange}
                onDragEnd={this.onSenDragEnd}
              />
            </Popover>
          </div>
        )}

        {start && motionLeft && <h4>Detected LEFT</h4>}
        {start && motionRight && <h4>Detected RIGHT</h4>}

        <div>
          <video style={{ display: "none" }} className="videoCls"></video>
        </div>
        <div>
          <canvas
            style={{ display: "none" }}
            className="canvasCls"
            width="320"
            height="240"
          ></canvas>
        </div>
      </div>
    )
  }
}

export default GestureControl
