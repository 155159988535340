const getMessageConfig = () => {
  let elem: HTMLElement = document.body.getElementsByClassName(
    "lightroot"
  )[0] as HTMLElement
  if (!elem) {
    elem = document.body.getElementsByClassName("darkroot")[0] as HTMLElement
  }

  return {
    getContainer: () => elem,
    maxCount: 3,
  }
}

export { getMessageConfig }
