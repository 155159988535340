import * as React from "react"

import styles from "./Loading.module.css"
import loading from "../../assets/img/loading.svg"
import { Button } from "react-bootstrap"

interface IProps {
  loadingMessage: string
}

const defaultProps = {
  loadingMessage: "Loading..." as string,
}

export class Loading extends React.Component<IProps> {
  public static defaultProps = defaultProps
  render() {
    return (
      <div className={styles.root}>
        <h1>{this.props.loadingMessage}</h1>
        <img src={loading} alt="Loading"></img>
        <h3>Stuck here too long?</h3>
        <Button href="mailto:l.h.lee2617@gmail.com" variant="dark">
          Report a Bug
        </Button>
      </div>
    )
  }
}

export default Loading
