import firebase from "firebase/app"
import "firebase/firestore"

const firebaseApp = firebase.initializeApp({
  // copy and paste your firebase credential here
  apiKey: "AIzaSyBQIUqDyliypV4aG9e5Sr-iZFKEZDcuOUw",
  authDomain: "tuneapp-85575.firebaseapp.com",
  databaseURL: "https://tuneapp-85575.firebaseio.com",
  projectId: "tuneapp-85575",
  storageBucket: "tuneapp-85575.appspot.com",
  messagingSenderId: "18113709935",
  appId: "1:18113709935:web:8d4ddc475bbe34267b4a9a",
  measurementId: "G-R77G0H0EQW",
})
const db = firebaseApp.firestore()

const generateIdInCollection = (collection: string) => {
  return db.collection(collection).doc().id
}

export { db, generateIdInCollection }
