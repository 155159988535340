import { db } from "./firebase"

// .then...
const writeToDb = (
  collection: string,
  docKey: string,
  payload: Object
): Promise<any> => {
  payload = {
    ...payload,
    write___time: new Date().getTime(),
  }
  return db.collection(collection).doc(docKey).set(payload)
}

const updateDb = (
  collection: string,
  docKey: string,
  payload: Object
): Promise<any> => {
  payload = {
    ...payload,
    write___time: new Date().getTime(),
  }
  return db.collection(collection).doc(docKey).update(payload)
}

// .then...
const deleteFromDb = (collection: string, docKey: string): Promise<any> => {
  return db.collection(collection).doc(docKey).delete()
}

export { writeToDb, deleteFromDb, updateDb }
