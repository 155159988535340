import { setLocalStorageValue } from "../storage/LocalStorageHandler"

export default (state = {}, action: any) => {
  switch (action.type) {
    case "TOGGLE_DARK_MODE":
      // console.log('dark mode: ' + action.payload);
      setLocalStorageValue("darkModeReducer", action.payload)
      return action.payload

    default:
      return state
  }
}
