import * as React from "react"
import { Document, Page } from "react-pdf"
import { pdfjs } from "react-pdf"
import { updateDb, PageTurnerKey } from "../../../../firebase"
import { message } from "antd"
import { Modal, Button, Row, Col } from "react-bootstrap"
import { getMessageConfig } from "../../../../containers/DarkModeContainers/CustomMessage"
import BootstrapSwitchButton from "bootstrap-switch-button-react"
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

interface IProps {
  file: File
  uniqueID: string
  currentPage: number
  connected: boolean
  incrPage: any
  decrPage: any
  writePageToDb: any
  handleDisconnect: any
}

interface IState {
  totalPages: number
  width: number
  height: number
  dualMode: boolean
}

export class PDFViewer extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props)
    this.state = {
      totalPages: 1,
      width: 0,
      height: 0,
      dualMode: false,
    }
  }

  componentDidMount = () => {
    this.updateWindowDimensions()
    window.addEventListener("resize", this.updateWindowDimensions)

    document.body.onkeydown = (e) => {
      const { incrPage, decrPage } = this.props
      const { totalPages, dualMode } = this.state

      const arrowRight = e.key === "ArrowRight" || e.keyCode === 39
      const arrowLeft = e.key === "ArrowLeft" || e.keyCode === 37
      if (arrowRight && this.increaseAllowed()) {
        incrPage(totalPages, dualMode)
      } else if (arrowLeft && this.decreaseAllowed()) {
        decrPage(totalPages, dualMode)
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions)
    // unsubscribe();

    const payload: Object = {
      // active: false,
      filename: "",
    }

    const { uniqueID } = this.props

    updateDb(PageTurnerKey, uniqueID, payload)
      .then(() => {})
      .catch(() => {
        message.config(getMessageConfig())
        message.error("Something went wrong.")
      })
    document.onkeydown = null
  }

  updateWindowDimensions = () => {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
    })
    if (window.innerWidth < 1200 && this.state.dualMode) {
      message.config(getMessageConfig())
      message.info("Switched to single page mode.")
      this.toggleDualMode()
      this.setState({
        dualMode: false, //force
      })
    }
  }

  onDocumentLoadSuccess = ({ numPages }: any) => {
    const { writePageToDb } = this.props
    this.setState({ totalPages: numPages })
    writePageToDb(1, numPages)
  }

  shortenFileName = (oriFileName: string) => {
    if (oriFileName.length > 20) {
      return oriFileName.substr(0, 17) + "..."
    }
    return oriFileName
  }

  toggleDualMode = () => {
    const { dualMode } = this.state
    const { currentPage, writePageToDb, uniqueID } = this.props
    this.setState({
      dualMode: !dualMode,
    })

    const payload: Object = {
      dualMode: !dualMode,
    }

    if (!dualMode) {
      message.config(getMessageConfig())
      message.info(
        "Second page might not appear if it doesn't fit in your device"
      )
    }

    updateDb(PageTurnerKey, uniqueID, payload)

    // need to set current page to closest odd number <= currentPage
    if (currentPage % 2 === 1) {
      // odd
    } else {
      // even
      writePageToDb(currentPage - 1, this.state.totalPages)
    }
  }

  getCurrentPageLabel = (): string => {
    const { currentPage } = this.props
    const { dualMode, totalPages } = this.state
    if (dualMode && currentPage !== totalPages) {
      return currentPage.toString() + "," + (currentPage + 1).toString()
    }
    return currentPage.toString()
  }

  decreaseAllowed = (): boolean => {
    const { currentPage } = this.props
    return currentPage > 1
  }

  increaseAllowed = (): boolean => {
    const { totalPages, dualMode } = this.state
    const { currentPage } = this.props
    return !(
      currentPage === totalPages ||
      (dualMode && currentPage % 2 === 1 && currentPage + 1 === totalPages)
    )
  }

  render() {
    const { totalPages, height, dualMode } = this.state
    const {
      connected,
      currentPage,
      incrPage,
      decrPage,
      handleDisconnect,
      uniqueID,
    } = this.props
    const { file } = this.props

    return (
      <div>
        <Modal.Header closeButton style={{ padding: 8 }}>
          <h5 style={{ margin: "auto" }} className="hiddenOnMobile">
            {this.shortenFileName(file.name)}
          </h5>
          <Button
            variant="warning"
            active
            disabled={!this.decreaseAllowed()}
            onClick={() => decrPage(totalPages, dualMode)}
          >
            <h5 style={{ margin: 0 }}>&#60;</h5>
          </Button>
          <h5 style={{ margin: "auto 10px" }}>
            {this.getCurrentPageLabel()}/{totalPages}
          </h5>
          <Button
            variant="warning"
            active
            disabled={!this.increaseAllowed()}
            onClick={() => incrPage(totalPages, dualMode)}
          >
            <h5 style={{ margin: 0 }}>&#62;</h5>
          </Button>

          <div className="showOnWide" style={{ margin: "auto" }}>
            {/* 
  // @ts-ignore */}
            <BootstrapSwitchButton
              width={100}
              checked={this.state.dualMode}
              onChange={this.toggleDualMode}
              onlabel="Dual"
              offlabel="Single"
              onstyle="success"
              offstyle="success"
            />
          </div>

          <h5 style={{ margin: "auto" }}>
            {connected && (
              <Button variant="danger" onClick={() => handleDisconnect()}>
                Disconnect
              </Button>
            )}
            {!connected && <span>Disconnected - {uniqueID}</span>}
          </h5>
        </Modal.Header>

        <Modal.Body>
          <Document file={file} onLoadSuccess={this.onDocumentLoadSuccess}>
            <Row className="justify-content-center">
              <Col md="auto" style={{ padding: 0 }}>
                <Page
                  pageNumber={currentPage}
                  height={height - 2}
                  renderTextLayer={false}
                  renderMode="canvas"
                />
              </Col>

              {dualMode && currentPage !== totalPages && (
                <Col md="auto" style={{ padding: 0 }}>
                  <Page
                    pageNumber={currentPage + 1}
                    height={height - 2}
                    renderTextLayer={false}
                    renderMode="canvas"
                  />
                </Col>
              )}
            </Row>
          </Document>
        </Modal.Body>
      </div>
    )
  }
}

export default PDFViewer
