import { message } from "antd"
import { getMessageConfig } from "../containers/DarkModeContainers/CustomMessage"
const defaults: { [s: string]: string } = {
  darkModeReducer: "false",
  A4: "440",
  micLevel: "50",
  uniqueID: Math.floor(Math.random() * 10000).toString(),
  sensLevel: "30",
  devicemotionSensLevel: "75",
}

export const getLocalStorageValue = (key: string): string => {
  if (!Object.keys(defaults).includes(key)) {
    message.config(getMessageConfig())
    message.error("Something went wrong.")
    return ""
  }
  if (localStorage.getItem(key)) {
    // @ts-ignore
    const val: string = localStorage.getItem(key)
    return val
  }
  // return default if not found
  return defaults[key]
}

export const setLocalStorageValue = (key: string, val: string) => {
  if (!Object.keys(defaults).includes(key)) {
    message.config(getMessageConfig())
    message.error("Something went wrong.")
    return ""
  }
  localStorage.setItem(key, val)
}
