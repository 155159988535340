import * as React from "react"
import { Link } from "react-router-dom"
import { changelog } from "../../components/Changelog"
import styles from "./Footer.module.css"
import { toggleDarkMode } from "./../../actions/toggleDarkModeAction"
import { connect } from "react-redux"
import { Switch } from "antd"

interface IProps {
  toggleDarkMode: any
  darkModeReducer: any
}

interface IState {}

const defaultProps = {
  toggleDarkMode: null,
  darkModeReducer: false,
}

export class Footer extends React.Component<IProps, IState> {
  public static defaultProps = defaultProps

  toggleDarkMode = () => {
    this.props.toggleDarkMode(!this.props.darkModeReducer)
  }

  render() {
    return (
      <div className={styles.root}>
        <div className={styles.box}>
          <div className={styles.switch}>
            <Switch
              defaultChecked={this.props.darkModeReducer}
              checkedChildren="Dark Mode"
              unCheckedChildren="Light Mode"
              onChange={this.toggleDarkMode}
            />
          </div>
          <div className={styles.coffee}>
            <a
              href="https://www.buymeacoffee.com/8hwXuMlt3"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                src="https://bmc-cdn.nyc3.digitaloceanspaces.com/BMC-button-images/custom_images/yellow_img.png"
                alt="Buy Me A Coffee"
              />
            </a>
          </div>
          <h5>&copy;{new Date().getFullYear()} TuneApp</h5>
          <h5>
            TuneApp <Link to="/changelog">{Object.keys(changelog)[0]}</Link> |
            Created by L.H. Lee
          </h5>
          <span>
            <a href="mailto:l.h.lee2617@gmail.com">Email</a> |{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.linkedin.com/in/linhaolee"
            >
              LinkedIn
            </a>{" "}
            |{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://lhl2617.github.io"
            >
              Website
            </a>
          </span>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  ...state,
})

const mapDispatchToProps = (dispatch: any) => ({
  toggleDarkMode: (currentMode: boolean) =>
    dispatch(toggleDarkMode(currentMode)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Footer)
