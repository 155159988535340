import * as React from "react"
import NumericInput from "react-numeric-input"
import { Tag, Input, message } from "antd"
import { Modal, Button, Form, Row, Col } from "react-bootstrap"
import styles from "./Metronome.module.css"
import Slider from "react-input-slider"
import { playAudio, MetronomeClass } from "./Classes"
import { decodeAudioData, unlockAudio } from "../../common/UnlockAudio"
import { requestAnimFrame } from "../../common/RequestAnimFrame"
import { connect } from "react-redux"
import { CustomModalDialog } from "../../containers/DarkModeContainers/CustomModalDialog"
import { BouncyBall } from "./BouncyBall"
import BootstrapSwitchButton from "bootstrap-switch-button-react"
import { getMessageConfig } from "../../containers/DarkModeContainers/CustomMessage"
import { Popover } from "antd"
import { getMountNode } from "../../containers/DarkModeContainers/CustomMountNode"
import { mobileDetect } from "../../common/MobileDetect"

const woodblock = require("../../assets/sounds/metronome/woodblock.mp3").default
const bell = require("../../assets/sounds/metronome/ding.mp3").default
const hihatclosed = require("../../assets/sounds/metronome/hihatclosed.mp3")
  .default
const snarerim = require("../../assets/sounds/metronome/snarerim.mp3").default
const sticks = require("../../assets/sounds/metronome/sticks.mp3").default
const tone440 = require("../../assets/sounds/metronome/tone440.mp3").default
const tone880 = require("../../assets/sounds/metronome/tone880.mp3").default
const tone1760 = require("../../assets/sounds/metronome/tone1760.mp3").default

const soundMap: { [id: string]: any } = {
  Woodblock: woodblock,
  Bell: bell,
  "Closed hi-hat": hihatclosed,
  "Snare rim": snarerim,
  Sticks: sticks,
  "High tone": tone1760,
  "Mid tone": tone880,
  "Low tone": tone440,
}

class Sound {
  constructor(sound: string, volume: number) {
    this.sound = sound
    this.volume = volume
  }
  sound: string = "Bell"
  volume: number = 1.0 // between 0 and 1
}

interface IProps {
  darkModeReducer: boolean
}

const defaultProps = {
  darkModeReducer: false,
}

interface IState {
  tempo: number
  start: boolean
  groups: Array<number>
  inputValue: number
  showModal: boolean
  modalMsg: string
  mainSound: Sound
  subSound: Sound
  btnLoading: boolean
  currCounter: number
  ballEnabled: boolean
  ballPosition: number
  displayPopover1: boolean
  displayPopover2: boolean
}

let metronome: MetronomeClass

let mainAudioBuf: AudioBuffer
let subAudioBuf: AudioBuffer

export class Metronome extends React.Component<IProps, IState> {
  public static defaultProps = defaultProps

  constructor(props: any) {
    super(props)
    this.state = {
      tempo: 120,
      start: false,
      groups: [4],
      inputValue: 0,
      showModal: false,
      modalMsg: "",
      mainSound: new Sound("Bell", 0.8),
      subSound: new Sound("Woodblock", 0.8),
      btnLoading: true,
      currCounter: 0,
      ballEnabled: mobileDetect.mobile.length === 0,
      ballPosition: 0,
      displayPopover1: false,
      displayPopover2: false,
    }
  }

  componentDidMount = () => {
    fetch(soundMap[this.state.mainSound.sound])
      .then((resp) => resp.arrayBuffer())
      .then((buf) => {
        decodeAudioData(buf)
          .then((x) => {
            mainAudioBuf = x
          })
          .catch((e) => {
            console.error(e)
            message.config(getMessageConfig())
            message.error("Something went wrong.")
          })
      })

    fetch(soundMap[this.state.subSound.sound])
      .then((resp) => resp.arrayBuffer())
      .then((buf) => {
        decodeAudioData(buf)
          .then((x) => {
            subAudioBuf = x
            this.setState({
              ...this.state,
              btnLoading: false,
            })
          })
          .catch((e) => {
            console.error(e)
            message.config(getMessageConfig())
            message.error("Something went wrong.")
          })
      })
  }

  getMetronomeBodyStyles = () => {
    const { ballEnabled } = this.state
    if (ballEnabled) {
      return styles.metronomeBodyBall
    }
    return styles.metronomeBody
  }

  toggleBall = () => {
    this.setState({
      ...this.state,
      ballEnabled: !this.state.ballEnabled,
    })
  }

  render() {
    const {
      tempo,
      start,
      groups,
      inputValue,
      showModal,
      modalMsg,
      mainSound,
      subSound,
      btnLoading,
      currCounter,
      ballEnabled,
      displayPopover2,
      displayPopover1,
    } = this.state

    const setTempo = (valueAsNumber: number) => {
      this.setState({
        ...this.state,
        tempo: valueAsNumber,
      })
    }

    const handleClose = (index: any) => {
      const newGroups = groups.filter((x, i) => i !== index)
      this.setState({
        ...this.state,
        groups: newGroups,
      })
    }

    const handleInputChange = (e: any) => {
      this.setState({
        ...this.state,
        inputValue: e.target.value,
      })
    }

    const handleInputConfirm = () => {
      if (inputValue > 0 && inputValue < 100) {
        groups.push(Math.floor(inputValue))
        this.setState({
          ...this.state,
          groups: groups,
        })
      } else {
        message.config(getMessageConfig())
        message.error("Enter a positive integer less than 100")
      }
    }

    const handleCloseModal = () => {
      this.setState({
        ...this.state,
        showModal: false,
      })
    }

    const handleMainSoundChange = (e: any) => {
      this.setState({
        ...this.state,
        btnLoading: true,
        mainSound: new Sound(e.target.value, mainSound.volume),
      })
      fetch(soundMap[e.target.value])
        .then((resp) => resp.arrayBuffer())
        .then((buf) => {
          decodeAudioData(buf)
            .then((x) => {
              mainAudioBuf = x
              unlockAudio()
              playAudio(mainAudioBuf, this.state.mainSound.volume)
              this.setState({
                ...this.state,
                btnLoading: false,
              })
            })
            .catch((e) => {
              console.error(e)
              message.config(getMessageConfig())
              message.error("Something went wrong.")
            })
        })
    }

    const handleSubSoundChange = (e: any) => {
      this.setState({
        ...this.state,
        btnLoading: true,
        subSound: new Sound(e.target.value, subSound.volume),
      })
      fetch(soundMap[e.target.value])
        .then((resp) => resp.arrayBuffer())
        .then((buf) => {
          decodeAudioData(buf)
            .then((x) => {
              subAudioBuf = x
              unlockAudio()
              playAudio(subAudioBuf, this.state.subSound.volume)
              this.setState({
                ...this.state,
                btnLoading: false,
              })
            })
            .catch((e) => {
              console.error(e)
              message.config(getMessageConfig())
              message.error("Something went wrong.")
            })
        })
    }

    const handleMainVolumeState = (newVolume: any) => {
      this.setState({
        ...this.state,
        mainSound: new Sound(mainSound.sound, newVolume.x),
        displayPopover1: true,
      })
    }

    const handleMainVolumeChange = () => {
      this.setState({
        ...this.state,
        displayPopover1: false,
      })
      unlockAudio()
      playAudio(mainAudioBuf, this.state.mainSound.volume)
    }

    const handleSubVolumeState = (newVolume: any) => {
      this.setState({
        ...this.state,
        subSound: new Sound(subSound.sound, newVolume.x),
        displayPopover2: true,
      })
    }

    const handleSubVolumeChange = () => {
      this.setState({
        ...this.state,
        displayPopover2: false,
      })
      unlockAudio()
      playAudio(subAudioBuf, this.state.subSound.volume)
    }

    const handleStart = () => {
      metronome = new MetronomeClass(
        mainAudioBuf,
        mainSound.volume,
        subAudioBuf,
        subSound.volume,
        tempo,
        groups
      )
      metronome.start()
      this.setState({
        ...this.state,
        start: true,
        currCounter: 0,
      })
      unlockAudio()
      requestAnimFrame(getNextCounter)
    }

    const handleStop = () => {
      metronome.stop()
      this.setState({
        ...this.state,
        start: false,
        currCounter: 0,
      })
    }

    const getCounterClass = () => {
      if (currCounter === 1) return styles.red
      return undefined
    }

    const getPosition = (elapsedTime: number, h: number, k: number) => {
      const a = (4 * k) / Math.pow(h * 2, 2)

      const ypos = k - a * Math.pow((elapsedTime % (h * 2)) - h, 2)

      return ypos
    }

    const getNextCounter = () => {
      const currTime = metronome.audioContext.currentTime
      let nextCounter: number = this.state.currCounter
      let nextBallPosition: number = 0
      while (
        metronome.notesInQueue.length &&
        metronome.notesInQueue[0].time < currTime
      ) {
        nextCounter = metronome.notesInQueue[0].currCounter
        metronome.notesInQueue.splice(0, 1)
      }
      if (ballEnabled)
        nextBallPosition = getPosition(
          currTime - metronome.startTime,
          30.0 / tempo,
          120
        )
      if (nextCounter !== this.state.currCounter) {
        this.setState({
          ...this.state,
          currCounter: nextCounter,
        })
      }
      if (ballEnabled && nextBallPosition !== this.state.ballPosition) {
        this.setState({
          ...this.state,
          ballPosition: nextBallPosition,
        })
      }
      if (this.state.start) requestAnimFrame(getNextCounter)
    }

    return (
      <div className={styles.root}>
        <div className={styles.numericInputWrapper}>
          <div className={styles.tempoInput}>
            <h1 className={styles.tempoFormTitle}>Tempo (BPM)</h1>
            <NumericInput
              onChange={setTempo}
              min={20}
              max={400}
              value={tempo}
              strict
            />
          </div>
        </div>
        <h1 className={styles.tempoFormTitle}>Main Beat every</h1>
        <div className={styles.tagGroup}>
          {groups.map((tag, index) => {
            const tagElem = (
              <span key={index}>
                <Tag
                  key={tag}
                  onClose={() => handleClose(index)}
                  closable
                  className={styles.tags}
                  visible
                >
                  <span className={styles.tagtext}>{tag}</span>
                </Tag>
                <span> + </span>
              </span>
            )
            return tagElem
          })}

          <span className={styles.tagInputMaster}>
            <Input
              type="number"
              className={styles.tagInput}
              value={inputValue}
              onChange={handleInputChange}
              onPressEnter={handleInputConfirm}
              // onBlur={handleInputConfirm}
            />
          </span>
        </div>

        <Row className="justify-content-md-center" style={{ paddingTop: 20 }}>
          <Col md="auto">
            <h1 className={styles.beatTitle}>Main Beat</h1>
            <Form className={styles.form}>
              <Form.Group controlId="mainSoundTypeForm">
                <Form.Label>
                  <h1 className={styles.soundFormTitle}>Sound</h1>
                </Form.Label>
                <Form.Control
                  as="select"
                  className={styles.soundFormBox}
                  value={mainSound.sound}
                  onChange={handleMainSoundChange}
                >
                  {Object.keys(soundMap).map((x, index) => (
                    <option key={index}>{x}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form>
            <div className={styles.volume}>
              <h1 className={styles.soundFormTitle}>Volume</h1>
              <Popover
                getPopupContainer={getMountNode()}
                placement="bottom"
                visible={displayPopover1}
                content={
                  <div>
                    <h6 style={{ margin: 0 }}>
                      {(mainSound.volume * 100).toFixed(0) + "%"}
                    </h6>
                  </div>
                }
              >
                <Slider
                  styles={{
                    active: {
                      backgroundColor: "#e74c3c",
                    },
                  }}
                  axis="x"
                  xstep={0.01}
                  xmin={0}
                  xmax={1}
                  x={mainSound.volume}
                  onChange={handleMainVolumeState}
                  onDragEnd={handleMainVolumeChange}
                />
              </Popover>
            </div>
          </Col>
          <Col md="auto">
            <h1 className={styles.beatTitle}>Normal Beat</h1>
            <Form className={styles.form}>
              <Form.Group controlId="subSoundTypeForm">
                <Form.Label>
                  <h1 className={styles.soundFormTitle}>Sound</h1>
                </Form.Label>
                <Form.Control
                  as="select"
                  className={styles.soundFormBox}
                  value={subSound.sound}
                  onChange={handleSubSoundChange}
                >
                  {Object.keys(soundMap).map((x, index) => (
                    <option key={index}>{x}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form>

            <div className={styles.volume}>
              <h1 className={styles.soundFormTitle}>Volume</h1>
              <Popover
                getPopupContainer={getMountNode()}
                placement="bottom"
                visible={displayPopover2}
                content={
                  <div>
                    <h6 style={{ margin: 0 }}>
                      {(subSound.volume * 100).toFixed(0) + "%"}
                    </h6>
                  </div>
                }
              >
                <Slider
                  styles={{
                    active: {
                      backgroundColor: "#686de0",
                    },
                  }}
                  axis="x"
                  xstep={0.01}
                  xmin={0}
                  xmax={1}
                  x={subSound.volume}
                  onChange={handleSubVolumeState}
                  onDragEnd={handleSubVolumeChange}
                />
              </Popover>
            </div>
          </Col>
        </Row>

        <div style={{ marginTop: "-10" }}>
          <h1 className={styles.tempoFormTitle}>Bouncing animation</h1>
          <BootstrapSwitchButton
            onChange={this.toggleBall}
            checked={this.state.ballEnabled}
            onlabel="ON"
            offlabel="OFF"
            onstyle="success"
            offstyle="danger"
          />
        </div>

        <Button
          style={{ marginTop: 30 }}
          size="lg"
          variant="dark"
          disabled={btnLoading || start}
          onClick={handleStart}
        >
          Start
        </Button>

        <Modal
          dialogAs={CustomModalDialog}
          show={showModal}
          onHide={handleCloseModal}
          centered
        >
          <Modal.Body>
            <h5>{modalMsg}</h5>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="dark" onClick={handleCloseModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          dialogAs={CustomModalDialog}
          show={start}
          onHide={handleStop}
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Metronome</Modal.Title>
          </Modal.Header>
          <Modal.Body className={this.getMetronomeBodyStyles()}>
            <h1 className={getCounterClass()}>{this.state.currCounter}</h1>
            {ballEnabled && <BouncyBall position={this.state.ballPosition} />}
          </Modal.Body>
          {mobileDetect.mobile() === "iPhone" && (
            <Modal.Footer className={styles.metronomeFooter}>
              <p>
                If audio pauses suddenly, please make sure your iPhone mute
                switch is off.
              </p>
            </Modal.Footer>
          )}
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  ...state,
})

export default connect(mapStateToProps)(Metronome)
