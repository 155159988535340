import React from "react"
import styles from "./Conductor.module.css"
import MIDIConductor from "./MIDI/MIDIConductor"
import AudioConductor from "./Audio/AudioConductor"
// import MusicXMLConductor from './MusicXML/MusicXMLConductor'
import midi from "../../assets/img/midi.png"
import audio from "../../assets/img/audio.png"
// import musicxml from '../../assets/img/musicxml.png';
import { Row, Col } from "react-bootstrap"
import { ModuleCard } from "./../../containers/HomePage/Card"
import { mobileDetect } from "../../common/MobileDetect"

const getContentFromPath = (path: string) => {
  if (path === `/conductor/midi`) return <MIDIConductor />
  if (path === `/conductor/audio`) return <AudioConductor />
  // if (path === `/conductor/musicxml`) return <MusicXMLConductor />
  return (
    <div>
      <h3 style={{ marginTop: 32 }}>Select music type</h3>
      <Row className={styles.pickrow}>
        <Col>
          <ModuleCard urlkey="conductor/midi" title="MIDI" img={midi} />
        </Col>
        <Col>
          <ModuleCard urlkey="conductor/audio" title="Audio" img={audio} />
        </Col>
        {/* <Col>
                <ModuleCard urlkey="conductor/musicxml" title="MusicXML" img={musicxml} />
            </Col> */}
      </Row>
    </div>
  )
}

const Conductor = () => {
  return (
    <div className={styles.root}>
      <h1>
        Conductor<sup>BETA</sup>
      </h1>
      {getContentFromPath(window.location.pathname)}

      {!mobileDetect.mobile() && (
        <p style={{ marginTop: 32 }}>
          You can use motion detection to conduct on your mobile device!
        </p>
      )}

      {/* {mobileDetect.mobile() === "iPhone" &&
            <p>If you don't hear anything, please make sure your iPhone mute switch is off, then reload the page</p>
        } */}
    </div>
  )
}

export default Conductor
