import { createBrowserHistory } from "history"

const history = createBrowserHistory()

// TO SCROLL TO TOP WHEN CHANGE LINK
history.listen(() => {
  window.scrollTo(0, 0)
})

export { history }
