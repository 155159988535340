import * as React from "react"
import { Button } from "react-bootstrap"
import error1 from "../../assets/img/error/01.png"
import error2 from "../../assets/img/error/02.jpg"
import error3 from "../../assets/img/error/03.jpg"
import error4 from "../../assets/img/error/04.png"

import styles from "./Error.module.css"

const imgArr = [error1, error2, error3, error4]

export class Error extends React.Component {
  getRandImg() {
    return imgArr[Math.floor(Math.random() * 4)]
  }
  render() {
    return (
      <div className={styles.root}>
        <h1>An error occured</h1>
        <img src={this.getRandImg()} alt="Error occured meme"></img>
        <h3>Try reloading or...</h3>
        <Button href="mailto:l.h.lee2617@gmail.com" variant="dark">
          Report a Bug
        </Button>
      </div>
    )
  }
}

export default Error
