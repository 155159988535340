const getMountNode = () => {
  let elem: HTMLElement = document.body.getElementsByClassName(
    "lightroot"
  )[0] as HTMLElement
  if (!elem) {
    elem = document.body.getElementsByClassName("darkroot")[0] as HTMLElement
  }
  return () => elem
}
export { getMountNode }
