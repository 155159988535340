import * as React from "react"
import styles from "./HomePage.module.css"
import { ModuleCard } from "./Card"
import { Row, Col } from "react-bootstrap"

import tuner from "../../assets/img/tuner.png"
import tone from "../../assets/img/tone.png"
import metronome from "../../assets/img/metronome.png"
import tap from "../../assets/img/tap.png"
import page from "../../assets/img/page.png"
import piano from "../../assets/img/piano.png"
import visualizer from "../../assets/img/visualizer.png"
import conductor from "../../assets/img/conductor.png"
import crossplatform from "../../assets/img/crossplatform.png"
import crossplatformdark from "../../assets/img/crossplatformdark.png"
import { connect } from "react-redux"

interface IProps {
  darkModeReducer: boolean
}

const defaultProps = {
  darkModeReducer: false,
}

export class HomePage extends React.Component<IProps> {
  public static defaultProps = defaultProps

  getImg = () => {
    const { darkModeReducer } = this.props
    if (darkModeReducer) {
      return crossplatformdark
    }
    return crossplatform
  }

  render() {
    return (
      <div className={styles.root}>
        <Row className={styles.row}>
          <Col>
            <ModuleCard
              urlkey="chromatictuner"
              title="Chromatic Tuner"
              img={tuner}
            />
          </Col>
          <Col>
            <ModuleCard urlkey="pageturner" title="Page Turner" img={page} />
          </Col>
          <Col>
            <ModuleCard
              urlkey="tonegenerator"
              title="Tone Generator"
              img={tone}
            />
          </Col>
          <Col>
            <ModuleCard urlkey="metronome" title="Metronome" img={metronome} />
          </Col>
          <Col>
            <ModuleCard urlkey="taptobpm" title="Tap-to-BPM" img={tap} />
          </Col>
          <Col>
            <ModuleCard
              urlkey="pianotomidi"
              title="Piano-to-MIDI"
              img={piano}
            />
          </Col>
          <Col>
            <ModuleCard
              urlkey="visualizer"
              title="MIDI Visualizer"
              img={visualizer}
            />
          </Col>
          <Col>
            <ModuleCard urlkey="conductor" title="Conductor" img={conductor} />
          </Col>
        </Row>
        <h1>TuneApp</h1>
        <h3>Your cross-platform practice companion</h3>
        <img
          className={styles.crossplatformImg}
          src={this.getImg()}
          alt="TuneApp"
        ></img>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  ...state,
})

export default connect(mapStateToProps)(HomePage)
