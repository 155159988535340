import React from "react"
import Dimensions from "react-dimensions"

interface IProps {
  containerWidth: number
  containerHeight: number
}

export class DimensionsProvider extends React.Component<IProps, {}> {
  render() {
    const { children } = this.props
    return (
      <div>
        {children &&
          typeof children === "function" &&
          children({
            containerWidth: this.props.containerWidth,
            containerHeight: this.props.containerHeight,
          })}
      </div>
    )
  }
}

export default Dimensions()(DimensionsProvider)
